import { useMemo } from 'react';
import { useGetTags } from '@wbk/contentful/api';
import { Button } from '@wbk/ui';
import { CloseIcon } from '@wbk/svg';
import useFilters from '@/context/filters/useSearch';
import useLocalization from '@/context/localization/useLocalization';

const SelectedTags = () => {
  const { locale, countryCode } = useLocalization();
  const { tag, selectFilter } = useFilters();

  const { data } = useGetTags({
    lang: locale,
    where: { country: { countryCode } },
  });

  const selectedTags = useMemo(() => {
    return data?.filter((t) => tag?.includes(t.slug));
  }, [data, tag]);

  if (!selectedTags?.length) return null;

  return selectedTags.map((t) => (
    <span
      key={t.id}
      className='flex items-center gap-1 rounded-full border px-2 py-0.5 text-sm capitalize'
    >
      {t.title}
      <Button
        theme='white'
        shape='text'
        className='p-1.5'
        onClick={() => {
          selectFilter({ key: 'tag', value: [t.slug] });
        }}
      >
        <CloseIcon className='h-3 w-3' />
      </Button>
    </span>
  ));
};

export default SelectedTags;
