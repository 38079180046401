import { RouteObject, redirect } from 'react-router-dom';

export const geaRoutes: RouteObject[] = [
  {
    path: '/:lang',
    children: [
      {
        path: 'rs',
        loader: () => redirect('https://eservices.gea.gov.sa'),
      },
    ],
  },
  {
    path: 'rs',
    loader: () => redirect('https://eservices.gea.gov.sa'),
  },
];
