import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ContentfulMedia } from '@wbk/contentful';
import { Carousel } from '@wbk/ui';
import type { ContentfulMedia as ContentfulMediaType } from '@wbk/contentful/api';

type Image = Pick<ContentfulMediaType, 'url' | 'title'>;

type Props = {
  poster: Image;
  carousel?: Image[];
  autoPlay?: boolean;
  className?: string;
};

const GalleryWithThumbs = ({ poster, carousel, className }: Props) => {
  const { lang } = useParams<{ lang: string }>();

  const list = useMemo(() => {
    if (carousel && carousel.length > 1) {
      return carousel;
    }

    return [poster];
  }, [carousel, poster]);

  return (
    <Carousel
      locale={lang}
      rootClassName={className}
      className='aspect-1 mx-auto max-w-[600px]'
      thumbClassName='mx-auto max-w-[600px] pt-2'
      thumbs={
        list.length > 1
          ? ({ scrollTo, selectedIndex }) => (
              <div className='flex gap-2'>
                {list.map((image, i) => (
                  <button
                    key={image.url + i}
                    className='aspect-1 relative shrink-0 overflow-hidden rounded-lg'
                    aria-label={image.title}
                    onClick={() => scrollTo(i)}
                  >
                    {i !== selectedIndex && <div className='bg-body/70 absolute inset-0 z-10' />}
                    <ContentfulMedia
                      className='relative block h-20 w-20 object-cover'
                      media={{
                        title: image.title,
                        url: image.url,
                        height: 600,
                        width: 600,
                        contentType: 'image',
                        sys: {
                          id: '',
                          publishedAt: '',
                        },
                      }}
                    />
                  </button>
                ))}
              </div>
            )
          : undefined
      }
    >
      {list.map((image, i) => (
        <div className='aspect-1 mr-1 shrink-0 basis-full' key={image.url + i}>
          <figure className='h-full w-full'>
            <ContentfulMedia
              className='h-full w-full overflow-hidden rounded-lg object-cover'
              media={{
                title: image.title,
                url: image.url,
                height: 600,
                width: 600,
                contentType: 'image',
                sys: {
                  id: '',
                  publishedAt: '',
                },
              }}
              placeholder
            />
          </figure>
        </div>
      ))}
    </Carousel>
  );
};

export default GalleryWithThumbs;
