type Props = {
  text: string;
};

const Copyright = ({ text }: Props) => {
  return (
    <div className='flex flex-col items-center justify-center text-center text-[13px] text-zinc-100'>
      <span className='md:text-center'>{text}</span>
    </div>
  );
};

export default Copyright;
