import { createContext } from 'react';

type FilterSingles = 'date' | 'price' | 'country' | 'city' | 'sort' | 'q';
type FilterMultiples = 'zone' | 'category' | 'tag';

export type FilterKeys = FilterSingles | FilterMultiples;

export type FiltersStateProps = {
  [Filter in FilterSingles]?: string | null;
} & { [Filter in FilterMultiples]?: string[] };

export type FiltersContextProps = {
  total: number;
  selectFilter: (
    props:
      | { key: FilterSingles; value: string | null; apply?: boolean }
      | { key: FilterMultiples; value: string[]; apply?: boolean }
  ) => void;
  /** For mobile sheet view, this method will apply the currenct state filters and push the url history */
  applyFilters: () => void;
  /** Will only apply these filters and remove all others
   * Useful for filters that has to rest of the filters
   * such as country/city
   */
  selectAndApply: (
    props: Array<
      { key: FilterSingles; value: string | null } | { key: FilterMultiples; value: string[] }
    >
  ) => void;
  /** Calling this will help in update filters state to match current URL */
  resetFilters: () => void;
  clearFilters: () => void;
};

const FiltersContext = createContext<FiltersStateProps & FiltersContextProps>({
  total: 0,
  selectFilter: () => {},
  applyFilters: () => {},
  selectAndApply: () => {},
  resetFilters: () => {},
  clearFilters: () => {},
});

export default FiltersContext;
