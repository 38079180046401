import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAnalytics } from '@wbk/analytics';
import { Button, ImageWithPlaceholder, ShareModal } from '@wbk/ui';
import { useGetExperienceDetail } from '@wbk/contentful/api';
import { useGetTicketingEvent } from '@wbk/api/hooks';
import { QueueView } from '@wbk/queue';
import { AdSlotLB, AdSlotMLB } from '@smc/ads';
import { CurrencyPriceLabel } from '@wbk/currency';
import {
  BuyTicketButton,
  CollapsableText,
  ContentfulButton,
  EventDateTime,
  MobileStickyBuy,
} from '@wbk/contentful';
import Seo from '@/components/headers/seo';
import FullpageAnimatedLogo from '@/components/ui/spinners/FullpageAnimatedLogo';
import { eventJsonLd } from '@/components/headers/seo/json-ld';
import Error404 from '@/components/error/404';
import { parseContentfulToAnalyticsEvent } from '@/util/events';
import GalleryWithThumbs from '@/components/events/gallery/GalleryWithThumbs';

const customCSS = (
  <style>{`
    @font-face {
      font-family: 'monument';
      src: local('monument'), url(/fonts/monument/variable.ttf);
      font-display: swap;
    } 
    #root {
      font-family:monument, fe, inter, sans-serif;
      background: url('https://wbk-assets.s3.me-south-1.amazonaws.com/house-of-hype/blue-bg.png');
      background-size: cover;
      background-position: 50% 80px;
      background-repeat: no-repeat;
    } 
    #main { 
      min-height: 100vh;
      min-height: 100svh;
      background-image: url('https://wbk-assets.s3.me-south-1.amazonaws.com/house-of-hype/gray-bg.png');
      background-size: 160% 50%;
      background-position: -60% 110%;
      background-repeat: no-repeat;
    }
    @media only screen and (min-width: 640px){
      #main {
        background-size: 90% 50%; 
      }
    }
    :is([dir="rtl"] #root){
      background-image: url('https://wbk-assets.s3.me-south-1.amazonaws.com/house-of-hype/blue-bg-ar.png');
    }
    :is([dir="rtl"] #main){
      background-image: url('https://wbk-assets.s3.me-south-1.amazonaws.com/house-of-hype/gray-bg-ar.png');
    }
    footer{
      background-color: #000;
    }
  `}</style>
);

const HouseOfHypePage = () => {
  const { lang } = useParams<{ lang: Language; slug: string }>();
  const { t } = useTranslation(['common', 'event']);
  const { viewItemEvent } = useAnalytics();
  const { data: event, isLoading } = useGetExperienceDetail({
    lang,
    limit: 1,
    where: { slug: 'house-of-hype' },
  });

  const { data: ticketingEvent, isLoading: hyLoading } = useGetTicketingEvent(
    {
      slug: event?.ticketingUrlSlug || '',
      lang,
    },
    { enabled: !!event?.ticketingUrlSlug }
  );

  useEffect(() => {
    // GA4 Event
    if (event) {
      viewItemEvent({
        event: parseContentfulToAnalyticsEvent(event),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  if (isLoading || hyLoading) {
    return (
      <>
        {customCSS}
        <FullpageAnimatedLogo />
      </>
    );
  }

  if (!event) {
    return <Error404 />;
  }

  return (
    <>
      {customCSS}
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(eventJsonLd(event, `/${lang}/experiences/house-of-hype`)),
        }}
      />

      <Seo
        title={event.seo?.title || event.title}
        description={event.seo?.description}
        image={event.seo?.image?.url || event.image11.url}
      />

      <section className='container flex flex-col items-start gap-6 space-y-6 pb-20 pt-10 xl:flex-row xl:space-y-0'>
        <div className='w-full grow'>
          <div className='space-y-4 pb-6'>
            {event.carousalCollection.items?.length > 0 ? (
              <div className='pb-4 xl:hidden'>
                <GalleryWithThumbs
                  poster={event.image11}
                  carousel={event.carousalCollection.items}
                />
              </div>
            ) : (
              <figure className='pb-2 xl:hidden'>
                <ImageWithPlaceholder
                  className='aspect-1 mx-auto w-full max-w-2xl overflow-hidden rounded-lg object-cover sm:hidden'
                  src={event.image11?.url}
                  width={600}
                  height={600}
                  alt={event.title}
                />
                <ImageWithPlaceholder
                  className='mx-auto hidden aspect-[3/1] w-full max-w-2xl overflow-hidden rounded-lg object-cover sm:block'
                  src={event.image31?.url}
                  width={600}
                  height={400}
                  alt={event.title}
                />
              </figure>
            )}
          </div>

          <img
            src='https://wbk-assets.s3.me-south-1.amazonaws.com/house-of-hype/hoh-logo.svg'
            className='w-full max-w-sm'
            alt='arrow'
          />
          <h1 className='h-0 text-4xl font-black uppercase opacity-0 md:text-5xl'>{event.title}</h1>
          <div className='flex w-full items-center justify-between pt-4'>
            {event.schedule ? (
              <EventDateTime schedule={event.schedule} showIcon={false} />
            ) : (
              <div />
            )}
            <ShareModal
              data={{
                title: event?.title,
                text: event?.title,
              }}
              poster={event.image11.url}
              className='text-sm'
            />
          </div>

          {!ticketingEvent && event.subtitle && (
            <p className='text-center md:text-start lg:hidden'>{event.subtitle}</p>
          )}
          <QueueView />

          <div
            className='hidden w-full gap-4 pt-4 text-xl font-thin lg:flex lg:justify-between [&>*]:rounded-none [&>div]:w-full'
            data-testid='buy-tickets-cta'
          >
            <BuyTicketButton
              event={event}
              className='rounded-none bg-[#4dff9f] font-bold uppercase text-black ring-[#4dff9f] ring-offset-[#3a33ff] hover:bg-[#3ed584] focus:bg-[#3ed584] active:bg-[#4dff9f] [&>div]:space-y-1 [&>div]:pt-0.5'
            />
          </div>

          <div className='space-y-2 py-6' data-testid='event-description'>
            <h2 className='text-2xl font-normal md:font-bold'>{t('event:about')}</h2>
            <CollapsableText
              text={event.description}
              btnProps={{ 'aria-label': `Read More About ${event.title}` }}
              markdown={typeof event.description === 'string'}
              minimum={12}
            />
          </div>

          {/* Ads */}
          <AdSlotLB adUnit='experiences/lb' />
          <AdSlotMLB adUnit='experiences/mlb' className='mb-0 pt-0' />

          {!!event.socialLinks?.iconLinksCollection?.items?.length && (
            <div
              className='space-y-2 border-b border-white/20 py-6'
              data-testid='event-description'
            >
              {event.socialLinks.title && <h2 className='text-xl'>{event.socialLinks.title}</h2>}
              <ul className='flex flex-wrap items-center gap-4'>
                {event.socialLinks?.iconLinksCollection?.items.map((link) => (
                  <li key={link.id} className='rounded-md'>
                    <ContentfulButton
                      link={link}
                      rel='noreferrer noopener'
                      className='event_social_media_follow overflow-hidden rounded-md p-0.5'
                      data-category={link.label}
                      rounded
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}

          {event.richTerms && (
            <div className='space-y-4 border-b border-white/20 py-6'>
              <h2 className='text-xl'>{t('common:nav.terms_and_conditions')}</h2>
              <CollapsableText
                text={event.richTerms}
                btnProps={{ 'aria-label': 'Read More About Terms' }}
              />
            </div>
          )}

          {event.schedule && (
            <div className='space-y-1 py-6'>
              <h2 className='text-xl'>{event?.schedule?.openTitle || t('event:date_time')}</h2>
              <EventDateTime schedule={event.schedule} showIcon={false} />
            </div>
          )}

          {event.location && (
            <div className='space-y-2 py-6'>
              <h2 className='text-2xl font-normal md:font-bold'>
                {event.location?.seactionHeader || t('event:location')}
              </h2>
              <div className='flex items-start justify-between gap-4'>
                <p className='flex items-start gap-2 text-sm'>
                  {event.location?.title && (
                    <>
                      <img
                        className='mt-0.5'
                        src='/icons/common/location-marker.svg'
                        width={15}
                        height={16}
                        alt='location'
                      />
                      {event.location?.title || ''}
                    </>
                  )}
                </p>
                <Button
                  as='a'
                  target='_blank'
                  rel='noreferrer'
                  href={`https://www.google.com/maps/search/?api=1&query=${event.location?.location?.lat},${event.location?.location?.lon}`}
                  className='shrink-0 bg-white/10 py-1 text-sm'
                  theme='white'
                  shape='text'
                >
                  {t('event:go_to_location')}
                  <img
                    src='/icons/common/location.svg'
                    width={15}
                    height={16}
                    className='shrink-0 rtl:scale-x-[-1]'
                    alt='direction'
                  />
                </Button>
              </div>
            </div>
          )}

          {/* Mobile sticky */}
          <MobileStickyBuy
            event={event}
            className='rounded-none bg-[#4dff9f] text-sm font-semibold text-black ring-[#4dff9f] hover:bg-[#3ed584] focus:bg-[#3ed584] active:bg-[#4dff9f] [&>div>p]:text-[10px]'
          />
        </div>

        <aside className='sticky top-24 mx-auto w-full max-w-xl shrink-0 grow gap-8'>
          <GalleryWithThumbs poster={event.image11} carousel={event.carousalCollection.items} />
        </aside>
      </section>

      <section className='container flex min-h-[500px] flex-col items-center gap-4 space-y-6 bg-[#27272a] py-20 sm:bg-transparent lg:flex-row lg:space-y-0'>
        {ticketingEvent?.event_tickets && (
          <>
            <h2 className='text-5xl font-semibold uppercase leading-[3.5rem] text-white md:max-w-sm'>
              {t('event:choose_tickets')}
            </h2>

            <ul className='flex w-full grow flex-wrap gap-6'>
              {ticketingEvent.event_tickets.map((ticket, i) => (
                <li key={i} className='max-w-sm grow basis-60'>
                  <a
                    href={`/${lang}/experiences/${ticketingEvent.slug}/book`}
                    className={`flex h-full w-full flex-col justify-between space-y-4 rounded-3xl px-8 py-6 transition duration-300 hover:scale-[1.02] hover:shadow-2xl ${i === 0 ? 'bg-white' : 'bg-body'}`}
                  >
                    <div className='space-y-1'>
                      <p
                        className={`text-lg font-semibold ${i === 0 ? 'text-black' : 'text-white'}`}
                      >
                        {ticket.title}
                      </p>
                      {ticket.description && (
                        <div
                          className={`text-xs ${i === 0 ? 'text-gray-600' : 'text-white'}`}
                          dangerouslySetInnerHTML={{ __html: ticket.description }}
                        />
                      )}
                    </div>

                    <div className='space-y-4'>
                      <div className='space-y-1'>
                        <p
                          className={`text-xs font-light ${i === 0 ? 'text-gray-600' : 'text-gray-100'}`}
                        >
                          {t('event:starts_from')}
                        </p>
                        <CurrencyPriceLabel
                          className={`flex items-baseline gap-2 ${i > 0 ? 'text-white' : 'text-black'}`}
                          originalPrice={+ticket.price + +ticket.vat}
                          currency={ticket.currency}
                        />
                      </div>

                      <Button
                        className={`w-full ${
                          i > 0
                            ? 'ring-offset-body bg-zinc-700 ring-zinc-700 hover:bg-zinc-800 focus:bg-zinc-800 active:bg-zinc-700'
                            : 'bg-[#4dff9f] text-black ring-[#4dff9f] ring-offset-white hover:bg-[#3ed584] focus:bg-[#3ed584] active:bg-[#4dff9f]'
                        }`}
                        tabIndex={-1}
                        arrow
                        theme={i === 0 ? 'primary' : 'black'}
                      >
                        {t('event:buy_tickets')}
                      </Button>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </>
        )}
      </section>
    </>
  );
};

export default HouseOfHypePage;
