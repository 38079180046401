import { useTranslation } from 'react-i18next';
import { WebsiteConfig } from '@wbk/contentful/api';
import { ContentfulButton } from '@wbk/contentful';
import PaymentMethods from '@/components/common/PaymentMethods';
import LanguageCurrencySwitch from '../../common/LanguageCurrencySwitch';
import Copyright from './Copyright';

type Props = {
  config: WebsiteConfig;
};

const MainFooter = ({ config }: Props) => {
  const { t } = useTranslation(['common']);
  return (
    <footer className='border-paper border-t'>
      <div className='container px-6 pb-6'>
        <div className='max-lg:flex max-lg:flex-col'>
          <nav className='border-paper flex flex-wrap border-b py-6 text-xs lg:hidden'>
            <ul className='flex flex-col flex-wrap gap-5 space-y-0' role='menu'>
              {config.footer.bottomLinksCollection.items.map((link) => (
                <li key={link.id} role='presentation'>
                  <ContentfulButton
                    data-testid={`footer_mobile_link_${link.id}`}
                    role='menuitem'
                    link={link}
                    theme='white'
                    rel='noreferrer noopener'
                    className='p-0'
                    data-location='footer'
                    data-category={link.label}
                  />
                </li>
              ))}
            </ul>
          </nav>
          <div className='order-3 flex flex-row items-center pt-6 xl:justify-between'>
            <div className='flex grow flex-row items-center gap-4 space-y-0 lg:gap-6'>
              <figure className='shrink-0'>
                <img
                  src={config.footer.logo.url}
                  width={config.footer.logo.width}
                  height={config.footer.logo.height}
                  alt={config.title}
                  className='max-w-16 lg:max-w-24'
                />
              </figure>
              <Copyright text={config.footer.copyrightText} />
            </div>
            <div role='presentation'>
              <LanguageCurrencySwitch />
            </div>
          </div>
          <div className='order-4 flex items-center justify-between pb-1 xl:flex-row'>
            <nav className='hidden flex-wrap py-2 text-xs lg:flex'>
              <ul className='flex flex-row flex-wrap gap-5 space-y-0' role='menu'>
                {config.footer.bottomLinksCollection.items.map((link) => (
                  <li key={link.id} role='presentation'>
                    <ContentfulButton
                      data-testid={`footer_link_${link.id}`}
                      role='menuitem'
                      link={link}
                      theme='white'
                      rel='noreferrer noopener'
                      className='p-0'
                      data-location='footer'
                      data-category={link.label}
                    />
                  </li>
                ))}
              </ul>
            </nav>
            <ul className='flex shrink-0 items-center'>
              {config.socialLinks?.iconLinksCollection?.items?.map((link) => (
                <li key={link.id} className='rounded-md'>
                  <ContentfulButton
                    data-testid={`footer_icon_link_${link.id}`}
                    link={link}
                    rel='noreferrer noopener'
                    className='social_media_follow overflow-hidden rounded-md !p-0'
                    data-location='footer'
                    data-category={link.label}
                    aria-label={link.label || link.href}
                    rounded
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className='border-paper order-2 flex flex-col items-center justify-between border-t pt-6 lg:flex-row'>
            <div className='border-paper order-2 flex w-full flex-col flex-wrap gap-4 border-b py-6 lg:order-1 lg:w-auto lg:border-none lg:py-0 xl:flex-row'>
              <div className='text-text-secondary flex flex-col items-center gap-2 text-xs lg:flex-row lg:pt-0'>
                <p>{t('common:footer.flexible_payment')}</p>
                <PaymentMethods onlyLogos className='scale-95' />
              </div>
            </div>
            <div className='border-paper order-1 flex w-full flex-col items-center justify-center gap-2 border-b pb-5 lg:order-2 lg:w-auto lg:flex-row lg:justify-end lg:border-none lg:pb-0'>
              <p className='text-center text-sm'>{config.footer.downloadAppPrompt}</p>

              <div className='flex items-center'>
                {config.footer.mobileAppLinksCollection.items.map((link) => (
                  <ContentfulButton
                    data-testid={`footer_download_app_link_${link.id}`}
                    key={link.id}
                    link={link}
                    disabled={!link?.href || link?.href === '#'}
                    className={!link?.href || link?.href === '#' ? 'opacity-40' : ''}
                    aria-label={link.label || 'Download App'}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default MainFooter;
