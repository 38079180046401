import { useEffect, useState } from 'react';
import { Slider } from '@wbk/ui';
import { useTranslation } from 'react-i18next';
import {
  useGetEventsList,
  useGetExperiences,
  useGetPackages,
  useGetShows,
} from '@wbk/contentful/api';
import { useCurrency } from '@wbk/currency';
import { formatPrice } from '@wbk/utils';
import { useDeviceDetect } from '@wbk/hooks';
import useFilters from '@/context/filters/useSearch';
import useExploreQuery from '@/hooks/useExploreQuery';

const ExplorePriceFilter = () => {
  const { currency } = useCurrency();
  const { t } = useTranslation('common');
  const { isMobile } = useDeviceDetect();
  const { price, country, city, selectFilter } = useFilters();
  const [localRange, setLocalRange] = useState([0, 0]);
  const { query } = useExploreQuery({});
  const enabled = !!query;
  const where = { location: city ? { cityCode: city } : country ? { countryCode: country } : {} };
  const order = 'startingPrice_DESC';
  const limit = 1;
  const { data: events } = useGetEventsList({ limit, order, where }, { enabled });
  const { data: exps } = useGetExperiences({ limit, order, where }, { enabled });
  const { data: shows } = useGetShows({ limit, order, where }, { enabled });
  const { data: packages } = useGetPackages({ limit, order, where }, { enabled });

  const event = events?.items?.[0];
  const experience = exps?.items?.[0];
  const show = shows?.items?.[0];
  const pkg = packages?.items?.[0];
  const eventPrice = event?.startingPrice || 0;
  const experiencePrice = experience?.startingPrice || 0;
  const showPrice = show?.startingPrice || 0;
  const packagePrice = pkg?.startingPrice || 0;
  const max = Math.max(eventPrice, experiencePrice, showPrice, packagePrice);

  const from = localRange?.[0];
  const to = localRange?.[1];

  useEffect(() => {
    if (!price) {
      setLocalRange([0, max]);
    }
  }, [max, price]);

  if (!max) {
    return null;
  }

  return (
    <>
      <hr className='border-paper my-4' />
      <div className='space-y-6 pb-4'>
        <div>
          <h2 className='text-2xl font-medium'>{t('common:price_range')}</h2>
          <p className='text-text-secondary'>
            {`${formatPrice(from)} ${currency.short_code} - ${formatPrice(to)} ${currency.short_code}`}
          </p>
        </div>
        <Slider
          key={max}
          aria-labelledby={t('common:price_range')}
          value={[from, to]}
          onValueCommit={(value) => {
            if (value?.[0] === 0 && value?.[1] === max) {
              return selectFilter({ key: 'price', value: null, apply: !isMobile });
            }

            selectFilter({ key: 'price', value: value.join('_'), apply: !isMobile });
          }}
          onValueChange={(value) => setLocalRange(value)}
          minStepsBetweenThumbs={2}
          step={max * 0.05}
          min={0}
          max={max}
        />
      </div>
    </>
  );
};

export default ExplorePriceFilter;
