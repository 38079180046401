import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { QueryProvider } from '@wbk/api/hooks';
import { router } from '@/routes';
import '@/i18n/config';
import '@/styles/index.css';
import '../api.config';
import '../contentful.config';
import '../logger.config';

const Wrapper = process.env.VITE_PUBLIC_ENV === 'development' ? React.StrictMode : React.Fragment;

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Wrapper>
    <QueryProvider>
      <RouterProvider router={router} />
    </QueryProvider>
  </Wrapper>
);
