import { motion } from 'framer-motion';

const Title = ({ children, className = '' }: { children: React.ReactNode; className?: string }) => {
  return (
    <motion.h2
      initial='hidden'
      animate='hidden'
      whileInView='visible'
      variants={{
        hidden: { opacity: 0, y: 25 },
        visible: { opacity: 1, y: 0 },
      }}
      className={`mx-auto mb-5 max-w-2xl text-center text-2xl font-extrabold uppercase leading-tight md:mb-10 md:text-[40px] ${className}`}
    >
      {children}
    </motion.h2>
  );
};

export default Title;
