import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetSurvey, useGetTicketingEvent } from '@wbk/api/hooks';
import { EventSurvey } from '@wbk/ticketing';
import FullpageAnimatedLogo from '@/components/ui/spinners/FullpageAnimatedLogo';
import Seo from '@/components/headers/seo';
import Error404 from '@/components/error/404';
const SurveyPage = () => {
  const { t } = useTranslation(['event', 'common']);

  const { slug, lang } = useParams<{ lang: Language; slug: string }>();
  const { data: event, isLoading } = useGetTicketingEvent({
    slug: slug || '',
    lang,
  });

  const { data: questions, isLoading: isQuestionsLoading } = useGetSurvey({ lang });

  if (isLoading || isQuestionsLoading) {
    return <FullpageAnimatedLogo />;
  }

  if (!event) {
    return <Error404 />;
  }

  return (
    <>
      <Seo
        title={`${t('event:rating.survey')} - ${event.seo?.title || event.title}`}
        description={event.seo?.description}
        image={event?.poster}
      />

      <EventSurvey event={event} questions={questions || []} />
    </>
  );
};

export default SurveyPage;
