import { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox } from '@wbk/ui';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { AddIcon } from '@wbk/svg';

type ListItem = { title: string; value: string; children?: ListItem[] };

type Props = {
  title: string;
  list?: ListItem[];
  value: string | string[];
  onChange: (value: string) => void;
  initialVisible?: number;
  loading?: boolean;
};

const FilterCheckboxList = ({
  title,
  list,
  value,
  onChange,
  loading,
  initialVisible = 5,
}: Props) => {
  const [showAll, setShowAll] = useState(false);
  const [showChildren, setShowChildren] = useState<{ [id: string]: boolean }>({});
  const { t } = useTranslation('common');

  const maxVisible = Math.min(list?.length || 0, initialVisible);

  const isChecked = useCallback(
    (itemVal: string) => {
      return Array.isArray(value) ? value.includes(itemVal) : value === itemVal;
    },
    [value]
  );

  const initialChecks = useCallback(() => {
    for (const items of list || []) {
      const hasSelectedChild = items.children?.some((child) => isChecked(child.value));
      if (hasSelectedChild) {
        setShowAll(true);
        setShowChildren((prev) => ({ ...prev, [items.value]: true }));
      }
    }
  }, [isChecked, list]);

  useEffect(() => {
    // Check if any of the children is selected
    initialChecks();
  }, [initialChecks]);

  if (loading) {
    return (
      <>
        <hr className='border-paper my-4' />
        <div className='space-y-3 py-2.5'>
          <div className='bg-text/5 h-7 max-w-[60%] animate-pulse rounded-md'></div>
          <div className='space-y-3 overflow-hidden px-0.5'>
            {[1, 2].map((i) => (
              <div key={i} className='bg-text/5 h-7 animate-pulse rounded-md'></div>
            ))}
          </div>
        </div>
      </>
    );
  }

  if (!list?.length) {
    return null;
  }

  return (
    <>
      <hr className='border-paper my-4' />
      <div className='space-y-4'>
        <h2 className='text-2xl font-medium'>{title}</h2>

        <motion.ul
          className='space-y-4 overflow-hidden px-0.5'
          initial={false}
          animate={{ height: showAll ? 'auto' : maxVisible * 40 - 16 }}
          transition={{ duration: 0.3 }}
        >
          {list.map((item) => {
            const checked = isChecked(item.value);
            const hasChildren = !!item.children?.length;
            return (
              <li key={item.value}>
                <div className='flex justify-between'>
                  <Checkbox
                    checked={checked}
                    name={item.value}
                    onChange={() => {
                      onChange(item.value);
                    }}
                    label={<p className='line-clamp-1'>{item.title}</p>}
                  />
                  {hasChildren && (
                    <div className='p-px'>
                      <Button
                        theme='white'
                        shape='text'
                        className='rounded-sm p-1'
                        onClick={() => {
                          setShowChildren((prev) => ({ ...prev, [item.value]: !prev[item.value] }));
                          // Find better solution with children case
                          setShowAll(true);
                        }}
                      >
                        <AddIcon className='h-3 w-3' />
                      </Button>
                    </div>
                  )}
                </div>
                {hasChildren && (
                  <motion.ul
                    className='space-y-3 overflow-hidden'
                    initial={false}
                    animate={{ height: showChildren[item.value] ? 'auto' : 0 }}
                  >
                    {item.children?.map((sub) => {
                      const subChecked = Array.isArray(value)
                        ? value.includes(sub.value)
                        : value === sub.value;
                      return (
                        <li key={sub.value} className='first:pt-2 ltr:ml-4 rtl:mr-4'>
                          <Checkbox
                            checked={subChecked}
                            name={sub.value}
                            onChange={() => {
                              onChange(sub.value);
                            }}
                            label={<p className='line-clamp-1'>{sub.title}</p>}
                          />
                        </li>
                      );
                    })}
                  </motion.ul>
                )}
              </li>
            );
          })}
        </motion.ul>

        {list.length > maxVisible && (
          <Button
            theme='white'
            shape='text'
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowAll((prev) => !prev);
            }}
            className='p-1 text-xs !ring-0 hover:underline focus:underline'
          >
            {t(`common:${showAll ? 'see_less' : 'see_all'}`)}
          </Button>
        )}
      </div>
    </>
  );
};

export default FilterCheckboxList;
