import { motion } from 'framer-motion';
import Title from './Title';

const experiences = [
  {
    img: '/business/experiences/riyadh-season.png',
    alt: 'Riyadh Season',
  },
  {
    img: '/business/experiences/stc.png',
    alt: 'STC (Formula 1 STC Saudi Arabian Grand Prix)',
  },
  {
    img: '/business/experiences/formula-e.png',
    alt: 'Formula E',
  },
  {
    img: '/business/experiences/ewc.png',
    alt: 'E-Sports World Cup',
  },
  {
    img: '/business/experiences/saudi-founding-day.png',
    alt: 'Saudi Founding Day',
  },
  {
    img: '/business/experiences/jeddah-season.png',
    alt: 'Jeddah Season',
  },
  {
    img: '/business/experiences/saudi-cup.png',
    alt: 'Saudi Cup',
  },
  {
    img: '/business/experiences/supercopa.png',
    alt: 'Supercopa de Espana',
  },
  {
    img: '/business/experiences/takeshi.png',
    alt: 'Takeshi Castle',
  },
  {
    img: '/business/experiences/wwe.png',
    alt: 'WWE',
  },
  {
    img: '/business/experiences/riyadh-marathon.png',
    alt: 'Riyadh Marathon',
  },
  {
    img: '/business/experiences/bitmap.png',
    alt: 'Gamers Without Borders',
  },

  {
    img: '/business/experiences/alawwal.png',
    alt: 'Alawwal',
  },
  {
    img: '/business/experiences/sfa.png',
    alt: 'SFA Domes',
  },
  {
    img: '/business/experiences/moc.png',
    alt: 'Ministry of Culture',
  },
  {
    img: '/business/experiences/beast.png',
    alt: 'MDL Beast',
  },
  {
    img: '/business/experiences/abb-formula-e.png',
    alt: 'ABB Formula E',
  },
  {
    img: '/business/experiences/mos.png',
    alt: 'Ministry of Sport',
  },
  {
    img: '/business/experiences/saff.png',
    alt: 'SAFF',
  },
  {
    img: '/business/experiences/gea.png',
    alt: 'General Entertainment Authority',
  },
  {
    img: '/business/experiences/spl.png',
    alt: 'Saudi Pro League',
  },
  {
    img: '/business/experiences/neom.png',
    alt: 'NEOM',
  },
  {
    img: '/business/experiences/ufc.png',
    alt: 'UFC',
  },
  {
    img: '/business/experiences/fcwc.png',
    alt: 'FIFA Club World Cup',
  },
  {
    img: '/business/experiences/sta.png',
    alt: 'Saudi Tourism Authority',
  },
  {
    img: '/business/experiences/diriyah.png',
    alt: 'Diriyah Season',
  },
  {
    img: '/business/experiences/golf.png',
    alt: 'UV Golf',
  },
  {
    img: '/business/experiences/wtcr.png',
    alt: 'WTCR',
  },
  {
    img: '/business/experiences/misk.png',
    alt: 'MISK',
  },
  {
    img: '/business/experiences/jcc.png',
    alt: 'Jeddah Courniche Circuit',
  },
];

const WbkExperiences = ({ title, showAll = false }: { title: string; showAll?: boolean }) => {
  return (
    <section className='bg-white py-12 text-black md:py-20'>
      <div className='container'>
        <Title className='pb-10 text-black md:pb-4'>{title}</Title>
        <motion.div
          initial='hidden'
          animate='hidden'
          whileInView='visible'
          variants={{
            visible: {
              transition: { staggerChildren: 0.07, delayChildren: 0.2 },
            },
            hidden: {
              transition: { staggerChildren: 0.05, staggerDirection: -1 },
            },
          }}
          viewport={{ once: true }}
          className='flex flex-wrap items-center justify-center gap-6'
        >
          {experiences.slice(0, showAll ? experiences.length : 14).map(({ img, alt }, i) => {
            return (
              <motion.div
                variants={{
                  hidden: { opacity: 0, scale: 0 },
                  visible: { opacity: 1, scale: 1 },
                }}
                transition={{
                  type: 'spring',
                  bounce: 0.3,
                }}
                key={i}
                className='flex h-32 w-32 items-center justify-center md:h-44 md:w-44'
              >
                <img src={img} alt={alt} className='max-h-full max-w-full' />
              </motion.div>
            );
          })}
        </motion.div>
      </div>
    </section>
  );
};

export default WbkExperiences;
