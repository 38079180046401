import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ContentfulEvent, useGetRestaurants } from '@wbk/contentful/api';
import { EventCarousel } from '@wbk/contentful';

type Props = {
  event: ContentfulEvent;
};

const SimilarRestaurants = ({ event }: Props) => {
  const { t } = useTranslation('event');
  const { lang } = useParams<{ lang: Language }>();
  const zoneSlug = event.zone?.slug;
  const { data: similars } = useGetRestaurants({
    lang,
    limit: 5,
    where: { slug_not: event.slug, zone: { slug: zoneSlug } },
  });

  if (!similars?.items?.length) return null;

  return (
    <div className='border-paper space-y-4 border-t py-12 [&>section>div>div>h2]:text-2xl [&>section]:space-y-4 [&>section]:py-0'>
      <EventCarousel
        section={{
          categoriesCollection: {
            items: [],
          },
          contentCollection: {
            items: similars.items as ({ __typename: 'Restaurants' } & ContentfulEvent)[],
          },
          title: t('event:you_might_also_like'),
          id: 'similar-events',
          webLinksCollection: {
            items: [],
          },
        }}
      />
    </div>
  );
};

export default SimilarRestaurants;
