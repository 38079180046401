import { useParams } from 'react-router-dom';
import { usePage } from '@wbk/contentful/api';
import Seo from '@/components/headers/seo';
import FullpageAnimatedLogo from '@/components/ui/spinners/FullpageAnimatedLogo';
import Error404 from '@/components/error/404';
import Hero from '@/components/spl/Hero';
import PageSection from '@/components/section';
import Tabs from '@/components/spl/Tabs';

const SPLTeamsPage = () => {
  const { lang } = useParams<{ lang: Language }>();
  const { data, isLoading, error } = usePage({ lang, limit: 1, where: { slug: 'spl-teams' } });

  if (error) {
    throw error;
  }

  if (isLoading) {
    return <FullpageAnimatedLogo />;
  }

  if (!data) {
    return <Error404 />;
  }

  return (
    <>
      <Seo
        title={data.seo?.title || data.title}
        description={data.seo?.description}
        image={data.seo?.image?.url}
      />

      <Hero data={data} />

      <Tabs active='teams' />

      {data?.sectionsCollection?.items?.map((section, idx) => (
        <PageSection key={section.__typename + idx} section={section} />
      ))}
    </>
  );
};

export default SPLTeamsPage;
