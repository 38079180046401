import { useMemo } from 'react';
import { useGetCategories } from '@wbk/contentful/api';
import { Button } from '@wbk/ui';
import { CloseIcon } from '@wbk/svg';
import useFilters from '@/context/filters/useSearch';
import useLocalization from '@/context/localization/useLocalization';

const SelectedCategories = () => {
  const { locale, countryCode } = useLocalization();
  const { category, selectFilter } = useFilters();

  const { data } = useGetCategories({
    lang: locale,
    where: { country: { countryCode } },
  });

  const selectedCats = useMemo(() => {
    const falttedCats = data?.flatMap((cat) => [cat].concat(cat.childrenCollection?.items || []));
    return falttedCats?.filter((cat) => category?.includes(cat.slug));
  }, [category, data]);

  if (!selectedCats?.length) return null;

  return selectedCats.map((cat) => (
    <span
      key={cat.id}
      className='flex items-center gap-1 rounded-full border px-2 py-0.5 text-sm capitalize'
    >
      {cat.title}
      <Button
        theme='white'
        shape='text'
        className='p-1.5'
        onClick={() => {
          selectFilter({ key: 'category', value: [cat.slug] });
        }}
      >
        <CloseIcon className='h-3 w-3' />
      </Button>
    </span>
  ));
};

export default SelectedCategories;
