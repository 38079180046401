import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from '@wbk/ui';

type Tab = {
  label: string;
  id: string;
};

const tabs: Tab[] = [
  { label: 'overview', id: 'overview' },
  //   { label: 'Results', id: 'results' },
  //   { label: 'Table', id: 'table' },
  { label: 'teams', id: 'teams' },
];

const Tabs = ({ active }: { active: string }) => {
  const { t } = useTranslation(['event']);

  return (
    <div className='container'>
      <div className='flex border-b border-white/20'>
        {tabs.map((tab) => (
          <Button
            key={tab.id}
            as={Link}
            to={active === 'overview' ? '/spl-teams' : '/spl'}
            className={`rounded-none py-4 capitalize md:text-lg ${
              active === tab.id ? 'border-b-2 border-white text-white' : 'text-text-secondary'
            }`}
            theme='transparent'
            shape='text'
          >
            {t(tab.label)}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
