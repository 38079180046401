import { useMemo } from 'react';
import { useEmailProviders } from '@wbk/hooks';
import { Button, Input, MobileInput, Typography } from '@wbk/ui';
import { COUNTRIES, EMAIL_PATTERN } from '@wbk/utils';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AdvertiseFormValues } from './types';

const ContactInfo = () => {
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useFormContext<AdvertiseFormValues>();

  const { t } = useTranslation(['common', 'profile', 'advertise']);
  const { data: emailProviders } = useEmailProviders();

  const phoneCountry = watch('phoneCountry');

  const selectedCountry = useMemo(() => {
    return COUNTRIES.find((c) => c.code === phoneCountry?.code);
  }, [phoneCountry?.code]);

  const onSubmit = async () => {
    setValue('mode', 'booking_details');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='w-full max-w-lg space-y-6'>
      <Typography variant='heading-L' as='h2'>
        1. {t('advertise:contact_info.title')}
      </Typography>
      <Controller
        name='full_name'
        control={control}
        rules={{
          required: t('common:validation.required'),
          maxLength: {
            value: 50,
            message: t('common:validation.max_length', { length: 50 }),
          },
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            value={value}
            label={<span className='font-normal'>{t('advertise:contact_info.full_name')}</span>}
            id='full_name'
            error={error?.message}
            onChange={onChange}
          />
        )}
      />

      <Controller
        name='company_name'
        control={control}
        rules={{
          required: t('common:validation.required'),
          maxLength: {
            value: 50,
            message: t('common:validation.max_length', { length: 50 }),
          },
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            value={value}
            label={<span className='font-normal'>{t('advertise:contact_info.company_name')}</span>}
            id='company_name'
            error={error?.message}
            onChange={onChange}
          />
        )}
      />

      <Controller
        name='position'
        control={control}
        rules={{
          required: t('common:validation.required'),
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            value={value}
            label={<span className='font-normal'>{t('advertise:contact_info.position')}</span>}
            id='position'
            error={error?.message}
            onChange={onChange}
          />
        )}
      />

      <Controller
        name='phone'
        control={control}
        rules={{
          required: t('common:validation.required'),
          validate: (value) => {
            const hasValidation = selectedCountry?.mobile_starts_with?.length;
            if (hasValidation) {
              const validStart = selectedCountry?.mobile_starts_with?.some((start) =>
                value.startsWith(start)
              );
              return validStart || t('common:validation.invalid_mobile');
            }

            return true;
          },
        }}
        render={({ field: { value, ...props } }) => (
          <MobileInput
            {...props}
            label={<span className='font-normal'>{t('advertise:contact_info.phone')}</span>}
            country={phoneCountry}
            mobile={value}
            onChange={(name, value) => {
              if (name === 'country') {
                setValue('phoneCountry', value as AdvertiseFormValues['phoneCountry']);
              } else {
                setValue('phone', value as AdvertiseFormValues['phone']);
              }
            }}
            error={errors?.phone?.message || errors?.phoneCountry?.message}
            placeholder={
              selectedCountry
                ? `${selectedCountry?.mobile_starts_with?.[0] || ''}${'x'.repeat(
                    (selectedCountry.phone_number_lengths?.[0] || 7) - 1
                  )}`
                : ''
            }
          />
        )}
      />

      <Controller
        control={control}
        name='email'
        rules={{
          required: t('common:validation.required'),
          pattern: {
            value: EMAIL_PATTERN,
            message: t('common:validation.invalid_email'),
          },
          validate: (value) => {
            const containsEmailProvider = emailProviders?.some((provider) =>
              value.endsWith(provider)
            );
            return containsEmailProvider ? t('common:validation.only_company_emails') : true;
          },
        }}
        defaultValue=''
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div>
            <Input
              value={value}
              type='email'
              label={<span className='font-normal'>{t('advertise:contact_info.email')}</span>}
              placeholder='you@email.com'
              onChange={onChange}
              error={error?.message}
            />
          </div>
        )}
      />

      <div className='flex justify-end'>
        <Button theme='action' type='submit'>
          {t('common:next')}: {t('advertise:booking_details.title')}
        </Button>
      </div>
    </form>
  );
};

export default ContactInfo;
