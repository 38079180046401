import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@wbk/ui';

const NeedHelp = () => {
  const { t } = useTranslation(['event']);

  const { lang } = useParams<{ lang: string }>();
  return (
    <div className='border-paper flex items-center justify-between rounded-xl border p-4'>
      <p className='text-xl font-semibold'>{t('event:need_help')}</p>

      <Button as={Link} to={`/${lang}/faqs`} arrow theme='action' className='text-sm'>
        {t('event:read_faqs')}
      </Button>
    </div>
  );
};

export default NeedHelp;
