import { ContentfulButton } from '@wbk/contentful';
import { useLocation } from 'react-router-dom';

type Props = React.ComponentProps<typeof ContentfulButton>;

const NavLink = ({ link, className = '', ...rest }: Props) => {
  const { pathname } = useLocation();
  const isActive = pathname === link.href;

  return (
    <ContentfulButton
      link={link}
      className={`whitespace-nowrap p-1 px-2 text-xs transition sm:text-sm ${link.shape === 'text' ? '' : 'w-full max-w-xs'} ${link.shape === 'text' && isActive ? 'text-text font-bold' : ''} ${className}`}
      data-title={link.label}
      data-location='header'
      role='menuitem'
      {...rest}
    />
  );
};

export default NavLink;
