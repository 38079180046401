import { Suspense } from 'react';
import { WebsiteConfig } from '@wbk/contentful/api';
import { useWindowResize } from '@wbk/hooks';
import { useCycle } from 'framer-motion';
import SearchProvider from '@/context/search/provider';
import DesktopHeader from '@/components/layout/header/DesktopHeader';
import MobileHeader from '@/components/layout/header/MobileHeader';
import TopNotice from './TopNotice';

type Props = {
  type?: LayoutType;
  config: WebsiteConfig;
};

const Header = ({ config, type }: Props) => {
  const { width } = useWindowResize();
  const [isMenuOpen, toggleMenu] = useCycle(false, true);
  const isMobile = width < 768;

  return (
    <SearchProvider>
      {type !== 'booking' && (
        <Suspense fallback={<></>}>{!isMenuOpen && <TopNotice config={config} />}</Suspense>
      )}
      <header
        data-type={type}
        data-sticky={type !== 'booking'}
        className='border-paper bg-body top-0 z-40 h-12 w-full border-b data-[sticky=true]:sticky data-[type=booking]:h-9 md:h-16 data-[type=booking]:md:h-12'
      >
        {isMobile ? (
          <MobileHeader
            type={type}
            config={config}
            isMenuOpen={isMenuOpen}
            toggleMenu={toggleMenu}
          />
        ) : (
          <DesktopHeader type={type} config={config} />
        )}
      </header>
    </SearchProvider>
  );
};

export default Header;
