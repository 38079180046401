import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { WebsiteConfig } from '@wbk/contentful/api';
import { ContentfulButton } from '@wbk/contentful';
import SideUserMenu from '@/components/layout/header/SideUserMenu';
import NavLink from './NavLink';

type Props = {
  config: WebsiteConfig;
  isMenuOpen: boolean;
  toggleMenu: () => void;
};
const SideMenu = ({ config, isMenuOpen, toggleMenu }: Props) => {
  const { t } = useTranslation(['common']);

  if (!config.header?.menuLinksCollection?.items?.length) {
    return null;
  }

  return (
    <>
      {isMenuOpen && <style>{'body{overflow:hidden}'}</style>}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            data-testid='header_mobile_toggle_side_menu_button'
            key='backdrop'
            onClick={toggleMenu}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className='bg-body/50 fixed inset-0 z-10 backdrop-blur-lg'
          />
        )}
      </AnimatePresence>
      <motion.nav
        className='mini-scrollbar bg-body fixed top-0 z-10 h-full max-h-screen w-full overflow-x-hidden ltr:left-0 rtl:right-0'
        initial={false}
        animate={isMenuOpen ? 'open' : 'closed'}
        variants={{
          open: { width: '100%', maxWidth: 450 },
          closed: { width: 0, maxWidth: 450 },
        }}
      >
        <div
          key={String(isMenuOpen)}
          className='sticky inset-x-0 top-0 z-[999] h-14 backdrop-blur-xl'
        ></div>

        <div className='divide-paper absolute z-[99] w-full divide-y px-4 pb-16'>
          <SideUserMenu open={isMenuOpen} onClose={toggleMenu} />
          {config.header.menuLinksCollection.items.map((link) => {
            if (!link) return null;
            return (
              <section key={link.id} className='space-y-2 py-4'>
                {link?.title && <div className='px-1 text-sm'>{link?.title}</div>}
                <ul className='space-y-1' role='menu'>
                  {link?.linksCollection?.items?.map((item) => (
                    <li
                      key={item.id}
                      className='line-clamp-1 p-1 text-lg [&>a]:justify-start [&>div>a]:justify-start'
                      role='presentation'
                    >
                      <NavLink link={item} onClick={toggleMenu} />
                    </li>
                  ))}
                </ul>
              </section>
            );
          })}

          {!!config?.socialLinks?.iconLinksCollection?.items?.length && (
            <div className='py-4'>
              <div className='text-text-secondary text-sm sm:text-base'>
                {t('common:footer.follow_us')}
              </div>
              <ul className='flex items-center gap-4'>
                {config?.socialLinks?.iconLinksCollection?.items?.map((link) => {
                  if (!link) return null;
                  return (
                    <li key={link.id} className='shrink-0 rounded-md'>
                      <ContentfulButton
                        link={link}
                        rel='noreferrer noopener'
                        className='social_media_follow h-12 w-12 overflow-hidden rounded-md !p-1 sm:h-auto sm:w-auto'
                        data-location='header'
                        data-category={link.label}
                        rounded
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </motion.nav>

      <button
        className='relative z-50 flex h-9 w-9 flex-col items-center justify-center space-y-[5px] focus:outline-none md:hidden lg:hidden xl:hidden'
        onClick={toggleMenu}
        aria-label={isMenuOpen ? 'Close Mobile Menu' : 'Open Mobile Menu'}
      >
        <AnimatePresence mode='popLayout' initial={false}>
          <motion.div
            key={1}
            initial={{ rotateZ: 0, x: 0 }}
            animate={{
              rotateZ: isMenuOpen ? -45 : 0,
              y: isMenuOpen ? '170%' : 0,
            }}
            className='h-0.5 w-6 rounded-full bg-white'
          />
          {!isMenuOpen && (
            <motion.div
              key={2}
              className='h-0.5 w-6 rounded-full bg-white'
              initial={{ x: '-100%', opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: '-100%', opacity: 0 }}
            />
          )}
          <motion.div
            key={3}
            initial={{ rotateZ: 0 }}
            animate={{
              rotateZ: isMenuOpen ? 45 : 0,
              y: isMenuOpen ? '-170%' : 0,
            }}
            className='h-0.5 w-6 rounded-full bg-white'
          />
        </AnimatePresence>
      </button>
    </>
  );
};

export default SideMenu;
