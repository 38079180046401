import React from 'react';
import { RouteObject } from 'react-router-dom';
import HouseOfHypePage from '@/pages/experiences/house-of-hype';
import SPLPage from '@/pages/spl';
import SPLTeamsPage from '@/pages/spl/teams';
import { PALM_WORLD_EVENT_SLUG } from '@/constants/plam-world';

const AhliFcPage = React.lazy(() => import('@/pages/events/ahli-fc'));
const WTAPage = React.lazy(() => import('@/pages/events/wta'));
const ATPPage = React.lazy(() => import('@/pages/events/atp'));
const WTADetailPage = React.lazy(() => import('@/pages/events/wta-detail'));
const IttihadPage = React.lazy(() => import('@/pages/events/ittihad'));
const JCSAPage = React.lazy(() => import('@/pages/events/jcsa'));
const JCSAEventGroupPage = React.lazy(() => import('@/pages/events/jcsa/group'));
const PageBySlug = React.lazy(() => import('@/pages/[slug]'));
const PalmWorldPage = React.lazy(() => import('@/pages/events/palm-world'));
const FormulaEPage = React.lazy(() => import('@/pages/events/formula'));

export const customPagesRoutes: RouteObject[] = [
  {
    path: 'experiences/house-of-hype',
    element: <HouseOfHypePage />,
  },
  {
    path: 'events/ahli-fc',
    element: <AhliFcPage />,
  },
  {
    path: 'ittihad',
    element: <IttihadPage />,
  },
  {
    path: 'events/next-gen-atp-2024-tickets-897329',
    element: <ATPPage />,
  },
  {
    path: 'events/wta-finals-tickets',
    element: <WTAPage />,
  },
  {
    path: 'events/wta-finals-tickets-697196',
    element: <WTADetailPage slug='wta-finals-tickets-697196' />,
  },
  {
    path: 'events/wta-weekend-847283',
    element: <WTADetailPage slug='wta-weekend-847283' />,
  },
  {
    path: 'events/wta-weekdays-379274',
    element: <WTADetailPage slug='wta-weekdays-379274' />,
  },
  {
    path: 'events/wta-finals-package-121829',
    element: <WTADetailPage slug='wta-finals-package-121829' />,
  },
  {
    path: 'events/wta-hospitality-926801',
    element: <WTADetailPage slug='wta-hospitality-926801' />,
  },
  {
    path: 'events/jcsa-homepage',
    element: <JCSAPage />,
  },
  {
    path: 'events/saudi-cup-taif-season',
    element: <JCSAEventGroupPage slug='saudi-cup-taif-season' />,
  },
  {
    path: 'events/riyadh-racing-season',
    element: <JCSAEventGroupPage slug='riyadh-racing-season' />,
  },
  {
    path: 'events/saudi-cup-season',
    element: <JCSAEventGroupPage slug='saudi-cup-season' />,
  },
  {
    path: 'spl',
    element: <SPLPage />,
  },
  {
    path: 'spl-teams',
    element: <SPLTeamsPage />,
  },
  {
    path: `events/${PALM_WORLD_EVENT_SLUG}`,
    element: <PalmWorldPage />,
  },
  {
    path: 'events/formula-e-25-3242',
    element: <FormulaEPage />,
  },
  // Must be last
  {
    path: ':slug',
    element: <PageBySlug />,
  },
];
