import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeviceDetect } from '@wbk/hooks';
import FullpageAnimatedLogo from '@/components/ui/spinners/FullpageAnimatedLogo';

interface Props {
  fallback?: string;
}
/**
 * AppCheckRedirect Component
 *
 * This component checks the user's device type. If the user is on a mobile device,
 * it attempts to open the mobile app or store. If the user is not on a mobile device,
 * they are redirected to a specified fallback page. By default, the fallback is the
 * "profile/bookings" (order history) page.
 *
 * @default 'profile/bookings' - Default fallback page if none is provided.
 *
 * @returns {JSX.Element} FullpageAnimatedLogo - A full-page animated logo displayed
 * while the redirect or app-opening process is in progress.
 */

const AppCheckRedirect = ({ fallback = 'profile/bookings' }: Props) => {
  const navigate = useNavigate();
  const { isMobile } = useDeviceDetect();
  const { lang } = useParams();

  useEffect(() => {
    if (!isMobile) {
      navigate(`/${lang}/${fallback}`);
    }

    const timer = setTimeout(() => {
      navigate(`/${lang}/${fallback}`);
    }, 2000);

    // Attempt to open the mobile app
    window.location.href = 'webook://tickets';

    return () => clearTimeout(timer);
  }, [navigate, isMobile, lang, fallback]);

  return <FullpageAnimatedLogo />;
};

export default AppCheckRedirect;
