import { createContext } from 'react';

export const FALLBACK_COUNTRY = 'SA';
export const FALLBACK_CURRENCY = 'SAR';

type Props = {
  locale: string;
  /** alpha-2 country code. It may be supported or not.
   * Use `countryCode` if you need to make sure it's supported country
   *  @default 'SA' */
  detectedCountryCode: string;
  /** The valid supported country code. @default null */
  countryCode: string | null;
  isSupportedCountry: boolean;
  /** The detected currency based on user IP address */
  detectedCurrency: string;
  fallbackCountry: string;
  detectLoading: boolean;
  setLocale: (locale: string) => Promise<void>;
};

const LanguageContext = createContext<Props>({
  detectedCountryCode: FALLBACK_COUNTRY,
  countryCode: null,
  detectedCurrency: FALLBACK_CURRENCY,
  isSupportedCountry: false,
  fallbackCountry: FALLBACK_COUNTRY,
  locale: '',
  detectLoading: true,
  setLocale: async () => {},
});

export default LanguageContext;
