import { useEffect, useState } from 'react';

const LETTERS = [
  'M120.799 1.78037V114.07C120.799 115.061 120.009 115.852 119.021 115.852H74.1786C73.4873 115.852 72.8948 115.456 72.5985 114.962L61.93 96.4606C61.2387 95.2737 59.5599 95.2737 58.8686 96.5595L48.2014 114.962C47.9051 115.456 47.3126 115.852 46.72 115.852H1.87666C0.790193 115.852 0 115.061 0 114.07V1.78037C0 0.791277 0.790193 0 1.87666 0H24.1993C24.7919 0 25.3844 0.395638 25.6807 0.89678L34.669 16.5245L42.7684 30.3718C43.4597 31.6643 45.1386 31.6643 45.8299 30.3718L58.8686 7.82045C59.5599 6.63354 61.2387 6.63354 61.93 7.82045L74.9687 30.3718C75.66 31.6643 77.3389 31.6643 78.0302 30.3718L86.1296 16.5245L95.1179 0.89678C95.4142 0.395638 96.0067 0 96.698 0H119.021C120.009 0 120.799 0.791277 120.799 1.78037Z',
  'M107.462 50.1617L96.7867 56.2954C95.6006 56.9877 95.6006 58.7681 96.7867 59.4605L107.462 65.5942L119.916 72.8159C120.509 73.114 120.799 73.7074 120.799 74.3998V102.1C120.799 102.693 120.509 103.288 119.916 103.684L99.2512 115.555C98.9612 115.753 98.6581 115.852 98.3682 115.852H1.77917C0.79074 115.852 0 115.061 0 114.07V1.78037C0 0.791277 0.79074 0 1.77917 0H98.3682C98.6581 0 98.9612 0.105504 99.2512 0.303323L119.916 12.1725C120.509 12.4626 120.799 13.0561 120.799 13.6495V41.4497C120.799 42.0432 120.509 42.6366 119.916 42.9399L107.462 50.1617Z',
  'M104.286 48.2811L90.3553 56.3943C89.1692 56.9877 89.1692 58.7681 90.3553 59.4605L104.286 67.4748L119.903 76.5758C120.496 76.8725 120.799 77.466 120.799 78.0594V114.07C120.799 115.061 120.008 115.852 119.02 115.852H74.132C73.4467 115.852 72.8536 115.456 72.5505 114.962L66.7253 104.871L61.9809 96.5595C61.2824 95.2737 59.5033 95.2737 58.818 96.5595L48.2352 114.962C47.8398 115.456 47.2468 115.852 46.6537 115.852H1.77917C0.79074 115.852 0 115.061 0 114.07V1.78037C0 0.791277 0.79074 0 1.77917 0H46.6537C47.2468 0 47.8398 0.395638 48.2352 0.89678L58.818 19.294C59.5033 20.4809 61.2824 20.4809 61.9809 19.294L72.5505 0.89678C72.8536 0.395638 73.4467 0 74.132 0H119.02C120.008 0 120.799 0.791277 120.799 1.78037V37.6911C120.799 38.3901 120.496 38.9836 119.903 39.2737L104.286 48.2811Z',
];

const AnimatedGrayLogo = ({ className = '' }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    // Increase index every 1 second
    const interval = setInterval(() => {
      setIndex((prev) => (prev + 1) % LETTERS.length);
    }, 300);

    return () => clearInterval(interval);
  }, []);

  return (
    <svg
      width='121'
      height='116'
      viewBox='0 0 121 116'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        opacity={0.2}
        fillRule='evenodd'
        clipRule='evenodd'
        d={LETTERS[index]}
        fill='currentColor'
      />
    </svg>
  );
};

export default AnimatedGrayLogo;
