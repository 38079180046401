import { CloseIcon, FilterIcon } from '@wbk/svg';
import { BottomSheet, Button } from '@wbk/ui';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import useFilters from '@/context/filters/useSearch';
import ExplorePriceFilter from './Price';
import ExploreZoneFilters from './Zone';
import ExploreCategoryFilters from './Category';
import ExploreDateFilters from './DateFilters';
import ExploreLocationFilter from './Location';
import ExploreTagsFilters from './Tags';

type Props = {
  className?: string;
  mobile?: boolean;
};

const ExploreFilters = ({ className, mobile }: Props) => {
  const { total, applyFilters, clearFilters, resetFilters } = useFilters();
  const { t } = useTranslation('common');

  if (mobile) {
    return (
      <div className='mb-6 flex items-center justify-between gap-2 lg:hidden [&>div]:grow'>
        <ExploreLocationFilter className='py-1' />
        <BottomSheet
          fullscreen
          ignoreHistory
          onClose={() => {
            resetFilters();
          }}
          render={({ open }) => (
            <Button
              theme='white'
              shape='outlined'
              className={twMerge(
                'relative flex h-[46px] shrink-0 items-center justify-center overflow-visible px-3 py-1 leading-none',
                className
              )}
              onClick={open}
            >
              <FilterIcon className='h-5 w-5' />
              <span className='xs:inline hidden'>{t('common:filters')}</span>
              {total > 0 && (
                <span className='bg-primary absolute top-0 flex h-5 w-5 shrink-0 -translate-y-1/2 items-center justify-center rounded-full font-sans text-xs font-semibold ltr:left-full ltr:-translate-x-1/2 rtl:right-full rtl:translate-x-1/2'>
                  {total}
                </span>
              )}
            </Button>
          )}
          body={({ close }) => (
            <>
              <div className='mb-5 flex items-center p-5'>
                <Button shape='text' theme='white' className='p-0' onClick={close}>
                  <CloseIcon className='h-5 w-5' />
                </Button>
                <p className='mx-auto'>{t('common:filters')}</p>
              </div>
              <List className='px-4 pb-28' />
            </>
          )}
          stickyFooter={({ close }) => (
            <div className='border-paper flex items-center justify-between gap-4 border-t px-4 py-3'>
              <Button
                shape='text'
                theme='white'
                onClick={() => {
                  close();
                  clearFilters();
                }}
              >
                {t('common:reset')}
              </Button>
              <Button
                className='h-12 max-w-[165px] grow'
                onClick={() => {
                  close();
                  applyFilters();
                }}
              >
                {t('common:apply')}
              </Button>
            </div>
          )}
        />
      </div>
    );
  }

  return <List className={className} />;
};

const List = ({ className }: { className?: string }) => {
  return (
    <div className={className}>
      <ExploreDateFilters />
      <ExplorePriceFilter />
      <ExploreCategoryFilters />
      <ExploreZoneFilters />
      <ExploreTagsFilters />
    </div>
  );
};

export default ExploreFilters;
