import { useTranslation } from 'react-i18next';
import { GroupEvent } from '@wbk/api';
import { CollapsableText } from '@wbk/contentful';

type Props = {
  event: GroupEvent;
};

const EventTitleDescription = ({ event }: Props) => {
  const { t } = useTranslation(['common', 'event']);

  const removePattern = (text: string) => {
    const pattern = /\d+__/;
    return text.replace(pattern, '');
  };

  const newText = removePattern(event.description_rt);

  return (
    <div className='space-y-2 border-t border-white/20 py-6' data-testid='event-description'>
      <h2 className='text-xl'>{t('event:about')}</h2>
      <CollapsableText
        text={newText}
        className='[&div]:prose [&>p]:m-0 [&>ul>li]:m-0 [&>ul]:m-0'
        btnProps={{ 'aria-label': `Read More About ${event.title}` }}
      />
    </div>
  );
};

export default EventTitleDescription;
