import { Button } from '@wbk/ui';
import { useTranslation } from 'react-i18next';
import { useGetCountries } from '@wbk/contentful/api';
import { NewsLetterSubscribe } from '@wbk/contentful';
import useFilters from '@/context/filters/useSearch';
import useLocalization from '@/context/localization/useLocalization';

const ExploreEmptyScreen = () => {
  const { locale } = useLocalization();
  const { clearFilters, total, country, city } = useFilters();
  const { t } = useTranslation('common');

  const { data, isLoading } = useGetCountries(
    { lang: locale, where: { countryCode: country } },
    { enabled: !!country, staleTime: Infinity }
  );

  const selectedCountry = data?.[0];
  const selectedCity = selectedCountry?.citiesCollection.items.find((c) => c.cityCode === city);
  const notSupportedCountry =
    typeof selectedCountry?.supported === 'boolean' && !selectedCountry.supported;
  const notSupportedCity = typeof selectedCity?.supported === 'boolean' && !selectedCity.supported;
  const notSupported = notSupportedCountry || notSupportedCity;

  const title = selectedCity?.title || selectedCountry?.title;

  return (
    <div className='space-y-6 py-4'>
      <img src='/icons/common/empty-search.svg' className='h-16 w-16' alt='' />

      {isLoading ? (
        <div className='space-y-4'>
          <div className='bg-body-dark/10 h-6 max-w-[600px] animate-pulse rounded-md' />
          <div className='bg-body-dark/10 h-6 max-w-[400px] animate-pulse rounded-md' />
          <div className='bg-body-dark/10 h-6 max-w-[300px] animate-pulse rounded-md' />
        </div>
      ) : notSupported ? (
        <div className='space-y-2'>
          <h4>{t('common:empty_search_not_supported', { country: title })}</h4>
          <p className='text-text-secondary text-sm'>
            {t('common:empty_search_not_supported_subscribe', { country: title })}
          </p>
          <NewsLetterSubscribe
            title=''
            type='newsletter'
            interests={[selectedCountry?.countryCode || '', title || 'N/A']}
            design='mini'
          />
        </div>
      ) : (
        <div className='space-y-0.5'>
          <h4>{t('common:empty_search')}</h4>
          <p className='text-text-secondary'>{t('common:empty_search_clear_filters')}</p>
        </div>
      )}
      {total > 0 && !notSupported && (
        <Button theme='white' onClick={clearFilters}>
          {t('common:reset_all_filters')}
        </Button>
      )}
    </div>
  );
};

export default ExploreEmptyScreen;
