import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { dateAdd, endOfDay, endOfWeek, parseDate, startOfDay, startOfWeek } from '@wbk/utils';
import { DEFAULT_PAGINATION } from '@/constants/pagination';
import useLocalization from '@/context/localization/useLocalization';

type Props = {
  /** Pre-defined query to prioritize
   * instead of the search params */
  predefined?: {
    lang?: Language;
    category?: string[];
    countryCode?: string;
    /** This will not apply any location based filter.
     * Useful for search query
     */
    locationGlobal?: boolean;
  };
};

const useExploreQuery = (props: Props) => {
  const predefined = props?.predefined;
  const { locale, countryCode, detectLoading } = useLocalization();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  const perPage = DEFAULT_PAGINATION;

  const categoriesQuery = useMemo(() => {
    if (predefined?.category?.length) {
      return {
        OR: [{ category: { slug_in: predefined.category } }],
      };
    }

    const categories = searchParams.getAll('category');
    if (categories.length) {
      return {
        OR: [{ category: { slug_in: categories } }],
      };
    }
    return {};
  }, [predefined?.category, searchParams]);

  const tagQuery = useMemo(() => {
    const tags = searchParams.getAll('tag');
    if (tags.length) {
      return {
        OR: [{ cmsTags: { slug_in: tags } }],
      };
    }
    return {};
  }, [searchParams]);

  const zoneQuery = useMemo(() => {
    const zones = searchParams.getAll('zone') || [];
    if (zones.length) {
      return {
        OR: [{ zone: { slug_in: zones } }],
      };
    }

    return {};
  }, [searchParams]);

  const priceQuery = useMemo(() => {
    const price = searchParams.get('price');
    const range = price?.split('_') || [];
    const from = Number(range?.[0]);
    const to = Number(range?.[1]);

    const valid = typeof from === 'number' && typeof to === 'number';
    if (valid && from >= 0 && to >= from) {
      return {
        AND: [{ startingPrice_gte: from }, { startingPrice_lte: to }],
      };
    }

    return {};
  }, [searchParams]);

  const sortQuery = useMemo(() => {
    const sortParam = searchParams.get('sort');
    switch (sortParam) {
      case 'latest':
        return 'sys_publishedAt_DESC';
      case 'oldest':
        return 'sys_publishedAt_ASC';
      case 'high_price':
        return 'startingPrice_DESC';
      case 'low_price':
        return 'startingPrice_ASC';
      default:
        return ['order_ASC', 'sys_publishedAt_DESC'];
    }
  }, [searchParams]);

  const locationQuery = useMemo(() => {
    // If global is needed, we will not filter by country
    if (predefined?.locationGlobal) {
      return {};
    }

    if (predefined?.countryCode) {
      return {
        OR: [{ location: { countryCode: predefined.countryCode } }],
      };
    }

    // Priority to search params city
    const city = searchParams.get('city');
    if (city) {
      return {
        OR: [{ location: { cityCode: city } }],
      };
    }

    // Then search params country, then detected country
    const country = searchParams.get('country') || countryCode;
    if (country) {
      return {
        OR: [{ location: { countryCode: country } }],
      };
    }

    return {};
  }, [countryCode, predefined?.countryCode, predefined?.locationGlobal, searchParams]);

  const searchQuery = useMemo(() => {
    const search = searchParams.get('q');
    if (search) {
      return {
        OR: [
          { title_contains: search },
          { description_contains: search },
          { category: { title_contains: search } },
          { cmsTags: { title_contains: search } },
          { location: { title_contains: search } },
          {
            seo: {
              OR: [
                { title_contains: search },
                { keywords_contains: search },
                { description_contains: search },
              ],
            },
          },
        ],
      };
    }
    return {};
  }, [searchParams]);

  const dateQuery = useMemo(() => {
    const date = searchParams.get('date');
    const isRange = date?.includes('_');

    let from: Date | undefined;
    let to: Date | undefined;

    if (isRange) {
      const [qFrom, qTo] = date?.split('_') || [];
      const safeFrom = parseDate(qFrom);
      const safeTo = parseDate(qTo);

      if (!!safeFrom?.getTime() && !!safeTo?.getTime()) {
        from = startOfDay(safeFrom);
        to = endOfDay(safeTo);
      }
    } else if (date === 'today') {
      from = startOfDay(new Date());
      to = endOfDay(new Date());
    } else if (date === 'tomorrow') {
      const tomorrowStart = dateAdd({ date: new Date(), interval: 'day', value: 1 });
      const tomorrowEnd = dateAdd({ date: new Date(), interval: 'day', value: 1 });
      if (tomorrowStart && tomorrowEnd) {
        from = startOfDay(tomorrowStart);
        to = endOfDay(tomorrowEnd);
      }
    } else if (date === 'thisweek') {
      from = startOfDay(startOfWeek(new Date()));
      to = endOfDay(endOfWeek(new Date()));
    }
    if (from && to) {
      return {
        OR: [
          {
            schedule: {
              openDateTime_lte: to,
              closeDateTime_gte: from,
            },
          },
          {
            schedule: {
              openDateTime_gte: from,
              openDateTime_lte: to,
            },
          },
          {
            schedule: {
              openDateTime_lte: to,
              closeDateTime_exists: false,
            },
          },
        ],
      };
    }

    return {};
  }, [searchParams]);
  /**
   * If still detecting country, return null until country resolved
   * because country is required for the query
   */
  const query = useMemo(() => {
    const obj: CommonFetchProps | null = null;

    if (detectLoading) {
      return obj;
    }

    return {
      lang: predefined?.lang || locale,
      limit: perPage,
      skip: (Number(page) - 1) * perPage,
      order: sortQuery,
      where: {
        AND: [
          { ...zoneQuery },
          { ...categoriesQuery },
          { ...tagQuery },
          { ...priceQuery },
          { ...searchQuery },
          { ...dateQuery },
          { ...locationQuery },
        ],
      },
    };
  }, [
    detectLoading,
    predefined?.lang,
    locale,
    perPage,
    page,
    sortQuery,
    zoneQuery,
    categoriesQuery,
    tagQuery,
    priceQuery,
    searchQuery,
    dateQuery,
    locationQuery,
  ]);

  return {
    query,
    search: searchParams.get('q') || '',
    perPage,
  };
};

export default useExploreQuery;
