import { useTranslation } from 'react-i18next';
import SearchCategories from './Categories';
import SearchFeaturedEvents from './SearchFeaturedEvents';

const EmptySearchResult = () => {
  const { t } = useTranslation('common');

  return (
    <>
      <div className='mx-auto max-w-lg space-y-4 p-4 text-center'>
        <img className='mx-auto h-20 w-20' src='/icons/common/empty-search.svg' alt='' />
        <p>{t('common:empty_search')}</p>
      </div>

      <SearchCategories />
      <SearchFeaturedEvents />
    </>
  );
};

export default EmptySearchResult;
