import { useDeviceDetect } from '@wbk/hooks';

type Props = {
  children: React.ReactNode;
};

const HideOnWebview = ({ children }: Props) => {
  const { isWebView, isSSR } = useDeviceDetect();

  if (isSSR || isWebView) return null;

  return children;
};

export default HideOnWebview;
