import { useGetCategories } from '@wbk/contentful/api';
import { useTranslation } from 'react-i18next';
import { useDeviceDetect } from '@wbk/hooks';
import useLocalization from '@/context/localization/useLocalization';
import useFilters from '@/context/filters/useSearch';
import FilterCheckboxList from './CheckboxList';

const ExploreCategoryFilters = () => {
  const { locale, countryCode } = useLocalization();
  const { t } = useTranslation('common');
  const { category, country, selectFilter } = useFilters();
  const { isMobile } = useDeviceDetect();
  const code = country || countryCode;
  const { data, isPending } = useGetCategories(
    {
      lang: locale,
      where: { country: { countryCode: code }, childrenCollection_exists: true },
    },
    {
      enabled: !!code,
    }
  );

  return (
    <FilterCheckboxList
      title={t('common:category')}
      list={data?.map((cat) => ({
        title: cat.title,
        value: cat.slug,
        // children: cat.childrenCollection?.items.map((child) => ({
        //   title: child.title,
        //   value: child.slug,
        // })),
      }))}
      value={category || []}
      onChange={(value) => selectFilter({ key: 'category', value: [value], apply: !isMobile })}
      loading={isPending}
    />
  );
};

export default ExploreCategoryFilters;
