export const PALM_WORLD_EVENT_SLUG = 'date-world-exhibition-2024';

export const PALM_WORLD_CSS = `
    #main {
    --main-bg: 32 48% 95%;
    --main-color: 0 0% 0%;

    font-family: 'year-of-the-camel';
    background: hsl(var(--main-bg));
    color: hsl(var(--main-color));

    --color-primary-100: 0 45% 95%;
    --color-primary-200: 0 45% 85%;
    --color-primary-300: 0 45% 65%;
    --color-primary-400: 0 45% 50%;
    --color-primary-500: 0 45% 38%; 
    --color-primary-600: 0 45% 30%;
    --color-primary-700: 0 45% 25%;
    --color-primary-800: 0 45% 20%;
    --color-primary-900: 0 45% 15%;
    --color-primary-contrast: 0 0% 100%;

    --color-text-primary: 0 0% 0%;
    --color-text-secondary: 240 10% 36%;

    --paper-border: 36 5% 78%;

    --bg-body: hsl(var(--main-bg));
    --bg-body-level-1: 32 48% 92%;
    --bg-body-dark: 0 0% 0%;
    --bg-body-light: 0 0% 100%;
    }

    @keyframes slideEn{
    100% {transform: translateX(-50%);}
    }

    @keyframes slideAr{
    100% {transform: translateX(50%);}
    }

    @font-face {
        font-family: 'year-of-the-camel';
        src: local('year-of-the-camel'), url(/palm-world/year-of-the-camel/bold.otf);
        font-weight: 800;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'year-of-the-camel';
        src: local('year-of-the-camel'), url(/palm-world/year-of-the-camel/extra-bold.otf);
        font-weight: 900;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'year-of-the-camel';
        src: local('year-of-the-camel'), url(/palm-world/year-of-the-camel/extralight.otf);
        font-weight: 200;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'year-of-the-camel';
        src: local('year-of-the-camel'), url(/palm-world/year-of-the-camel/light.otf);
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'year-of-the-camel';
        src: local('year-of-the-camel'), url(/palm-world/year-of-the-camel/medium.otf);
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'year-of-the-camel';
        src: local('year-of-the-camel'), url(/palm-world/year-of-the-camel/regular.otf);
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'year-of-the-camel';
        src: local('year-of-the-camel'), url(/palm-world/year-of-the-camel/thin.otf);
        font-weight: 100;
        font-style: normal;
        font-display: swap;
    }
`;
