import { RouteObject } from 'react-router-dom';
import FlightsPage from '@/pages/flights';
import FlightsListingPage from '@/pages/flights/search';
import FlightsSummaryPage from '@/pages/flights/summary';
import AppLayout from '@/App';

export const flightsRoutes: RouteObject[] = [
  {
    path: 'flights',
    element: <AppLayout />,
    children: [
      {
        path: '',
        element: <FlightsPage />,
      },
      {
        path: 'search',
        element: <FlightsListingPage />,
      },
    ],
  },
  {
    path: 'flights',
    element: <AppLayout type='booking' />,
    children: [
      {
        path: 'search/summary',
        element: <FlightsSummaryPage />,
      },
    ],
  },
];
