import { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import {
  BlogSection,
  CardsCarousel,
  CardsCarouselMini,
  CardsGrid,
  CardsSquareGrid,
  CollapsableTextSection,
  ContentfulStories,
  EventCarousel,
  EventFocusedCarousel,
  EventGrid,
  EventListItems,
  EventNumberedCarousel,
  FullScreenCarousel,
  HeroCounterSection,
  HeroSection,
  IconsGrid,
  InstagramCardsCarousel,
  NewsLetterSection,
  PollSection,
  RectangularCarousel,
  ShowcaseCarousel,
  SocialBannerSection,
  SocialCardsCarousel,
  SponsorList,
  SpotlightCarousel,
  WhatsNewSection,
  ZoneCardsCarousel,
  ZoneCarousel,
  ZoneGrid,
} from '@wbk/contentful';

import SearchProvider from '@/context/search/provider';
import ErrorBoundary from '../error/ErrorBoundary';
import HomeShopSection from '../shop/HomeShopSection';
import DownloadAppBanner from '../app/DownloadAppBanner';
import SearchSection from '../search/SearchSection';
import HomeSearch from '../search/HomeSearch';
import type { WebSection } from '@wbk/contentful/api';

type Props = {
  section: WebSection;
};

const ContentfulWebSection = ({ section }: Props) => {
  const { slug } = useParams<{ slug: string }>();

  // 1. Render based on section type
  switch (section.renderType) {
    case 'search-cards':
      return <SearchSection section={section} />;
    case 'search':
      return (
        <SearchProvider>
          <HomeSearch section={section} />
        </SearchProvider>
      );
    case 'collapsable-text':
      return <CollapsableTextSection section={section} />;
    case 'hero':
      return <HeroSection section={section} />;
    case 'hero-counter':
      return <HeroCounterSection section={section} />;
    case 'recap':
      return null;
    case 'stories':
      return <ContentfulStories section={section} />;
    case 'newsletter':
      return <NewsLetterSection section={section} slug={slug} />;
    case 'wbk-shop':
      return (
        <ErrorBoundary fallback={<></>}>
          <Suspense fallback={<></>}>
            <HomeShopSection />
          </Suspense>
        </ErrorBoundary>
      );
    case 'wbk-blog':
      return (
        <ErrorBoundary fallback={<></>}>
          <Suspense fallback={<></>}>
            <BlogSection section={section} />
          </Suspense>
        </ErrorBoundary>
      );
    case 'download-app':
      return <DownloadAppBanner />;
    case 'whats-new':
      return <WhatsNewSection section={section} />;
    case 'showcase-carousel':
      return <ShowcaseCarousel section={section} />;
    default:
      break;
  }

  //  2. Render based on content type
  if (!section.contentCollection?.items?.[0]) {
    return null;
  }

  const type = section.contentCollection?.items?.[0]?.__typename;

  switch (type) {
    case 'Zone':
      return section.renderType === 'fullwidth-carousel' ? (
        <FullScreenCarousel section={section} />
      ) : section.renderType === 'carousel' ? (
        <ZoneCarousel section={section} />
      ) : section.renderType === 'cards' ? (
        <ZoneCardsCarousel section={section} />
      ) : section.renderType === 'rectangular-carousel' ? (
        <RectangularCarousel section={section} />
      ) : section.renderType === 'category' ? (
        <IconsGrid section={section} />
      ) : (
        <ZoneGrid section={section} />
      );
    case 'WebCard':
      return section.renderType === 'fullwidth-carousel' ? (
        <FullScreenCarousel section={section} />
      ) : section.renderType === 'carousel' ? (
        <CardsCarousel section={section} />
      ) : section.renderType === 'social' ? (
        <SocialCardsCarousel section={section} />
      ) : section.renderType === 'cards' ? (
        <CardsCarouselMini section={section} />
      ) : section.renderType === 'spotlight' ? (
        <SpotlightCarousel section={section} />
      ) : section.renderType === 'rectangular-carousel' ? (
        <RectangularCarousel section={section} />
      ) : section.renderType === 'instagram-card' ? (
        <InstagramCardsCarousel section={section} />
      ) : section.renderType === 'cards-grid' ? (
        <CardsSquareGrid section={section} />
      ) : section.renderType === 'category' ? (
        <IconsGrid section={section} />
      ) : (
        <CardsGrid section={section} />
      );
    case 'Event':
    case 'Experience':
    case 'Shows':
    case 'Restaurant':
    case 'Package':
    case 'Auction':
      return section.renderType === 'fullwidth-carousel' ? (
        <FullScreenCarousel section={section} />
      ) : section.renderType === 'carousel' ? (
        <EventCarousel section={section} />
      ) : section.renderType === 'focused-carousel' ? (
        <EventFocusedCarousel section={section} />
      ) : section.renderType === 'numbered-carousel' ? (
        <EventNumberedCarousel section={section} />
      ) : section.renderType === 'list-item' ? (
        <EventListItems section={section} />
      ) : section.renderType === 'category' ? (
        <IconsGrid section={section} />
      ) : (
        <EventGrid section={section} />
      );

    case 'Category':
    case 'Tag':
      return section.renderType === 'rectangular-carousel' ? (
        <RectangularCarousel section={section} />
      ) : (
        <IconsGrid section={section} />
      );
    case 'SponsorList':
      return <SponsorList section={section} />;
    case 'Poll':
      return <PollSection section={section} />;
    case 'SocialBanner':
      return <SocialBannerSection section={section} />;
    default:
      return null;
  }
};

export default ContentfulWebSection;
