import { useTranslation } from 'react-i18next';
import Seo from '@/components/headers/seo';
import AdvertiseForm from '@/components/advertise/AdvertiseForm';

const AdvertisePage = () => {
  const { t } = useTranslation('advertise');
  return (
    <>
      <Seo title={t('advertise:title')} />
      <AdvertiseForm />
    </>
  );
};

export default AdvertisePage;
