import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from '@wbk/ui';
import { scrollToTop } from '@wbk/utils';
import { DEFAULT_PAGINATION } from '@/constants/pagination';

interface PaginationProps {
  total: number;
  skip?: number;
}

const Pagination = ({ total, skip = DEFAULT_PAGINATION }: PaginationProps) => {
  const { t } = useTranslation('common');
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const currentPage = Number(searchParams.get('page') || 1);
  const numberOfPages = Math.ceil(total / skip);
  const showPagination = total > skip;

  const paginationSuggestion = useMemo(() => {
    const prev = currentPage - 1;
    const hasPrev = prev > 1;
    const hasMorePrev = prev > 2;
    const next = currentPage + 1;
    const hasNext = next < numberOfPages;
    const hasMoreNext = next < numberOfPages - 1;

    return { prev, hasPrev, hasMorePrev, next, hasNext, hasMoreNext };
  }, [currentPage, numberOfPages]);

  if (!showPagination) return null;

  const navigateTo = (action: 'next' | 'prev' | number) => {
    const newUrl = new URLSearchParams(searchParams);

    if (action === 'prev') {
      const prevPage = Math.max(1, currentPage - 1);
      if (prevPage === 1) {
        newUrl.delete('page');
      } else {
        newUrl.set('page', `${Math.max(1, currentPage - 1)}`);
      }
    } else if (action === 'next') {
      newUrl.set('page', `${Math.min(currentPage + 1, numberOfPages)}`);
    } else {
      const selectedPage = Math.max(1, action);
      if (selectedPage === 1) {
        newUrl.delete('page');
      } else {
        newUrl.set('page', `${selectedPage}`);
      }
    }

    const search = newUrl.toString();
    const appended = search ? `?${search}` : '';
    navigate(`${pathname}${appended}`);

    setTimeout(() => {
      scrollToTop();
    }, 100);
  };

  return (
    <div className='flex items-center justify-center gap-2 py-6 font-sans'>
      {currentPage > 1 && (
        <button
          disabled={currentPage === 1}
          className='bg-body-level-1 flex h-7 w-7 shrink-0 items-center justify-center rounded-full border disabled:pointer-events-none disabled:opacity-30'
          onClick={() => navigateTo('prev')}
        >
          <img
            src='/icons/common/arrow.svg'
            width={20}
            height={20}
            className='ltr:rotate-180'
            alt={t('common:back')}
          />
        </button>
      )}

      <Button
        theme={currentPage === 1 ? 'primary' : 'white'}
        shape={currentPage === 1 ? 'solid' : 'text'}
        phill={false}
        rounded
        onClick={() => {
          if (currentPage > 1) {
            navigateTo(1);
          }
        }}
      >
        1
      </Button>

      {paginationSuggestion.hasMorePrev && <span className='text-zinc-400'>...</span>}
      {paginationSuggestion.hasPrev && (
        <Button
          key={paginationSuggestion.prev}
          theme='white'
          shape='text'
          phill={false}
          rounded
          onClick={() => {
            navigateTo(paginationSuggestion.prev);
          }}
        >
          {paginationSuggestion.prev}
        </Button>
      )}

      {currentPage > 1 && currentPage < numberOfPages && (
        <Button phill={false} rounded>
          {currentPage}
        </Button>
      )}

      {paginationSuggestion.hasNext && (
        <Button
          key={paginationSuggestion.next}
          theme='white'
          shape='text'
          phill={false}
          rounded
          onClick={() => {
            navigateTo(paginationSuggestion.next);
          }}
        >
          {paginationSuggestion.next}
        </Button>
      )}
      {paginationSuggestion.hasMoreNext && <span className='text-zinc-400'>...</span>}

      <Button
        theme={currentPage === numberOfPages ? 'primary' : 'white'}
        shape={currentPage === numberOfPages ? 'solid' : 'text'}
        phill={false}
        rounded
        onClick={() => {
          if (currentPage < numberOfPages) {
            navigateTo(numberOfPages);
          }
        }}
      >
        {numberOfPages}
      </Button>

      {currentPage < numberOfPages && (
        <button
          onClick={() => navigateTo('next')}
          className='bg-body-level-1 flex h-7 w-7 shrink-0 items-center justify-center rounded-full border disabled:pointer-events-none disabled:opacity-30'
          disabled={currentPage === numberOfPages}
        >
          <img
            src='/icons/common/arrow.svg'
            width={20}
            height={20}
            className='rtl:rotate-180'
            alt={t('common:back')}
          />
        </button>
      )}
    </div>
  );
};

export default Pagination;
