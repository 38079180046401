import { useAuth } from '@wbk/auth';

type Props = {
  children: React.ReactNode;
};

const HideOnTawakkalna = ({ children }: Props) => {
  const { isTawakkalnaUser } = useAuth();

  if (isTawakkalnaUser) {
    return null;
  }

  return <>{children}</>;
};

export default HideOnTawakkalna;
