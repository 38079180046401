export const getSafeRedirectRoute = (lang: Language = 'en', route?: string | null) => {
  if (!route) {
    return `/${lang}`;
  }

  const url = new URL(route, 'https://whatever.com');

  // If coming from shop, redirect to the shop
  if (route?.startsWith('/shop')) {
    return `https://webook.com${url.pathname}`;
  }

  return url.pathname;
};
