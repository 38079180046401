import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import { WebsiteConfig } from '@wbk/contentful/api';
import { ContentfulMedia } from '@wbk/contentful';

type Props = {
  config: WebsiteConfig;
};

const TopNotice = ({ config }: Props) => {
  const [dismissed, setDismissed] = useState(false);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);

  const cards = config.header.topNoticeCollection?.items;

  useEffect(() => {
    if (!cards) return;

    const interval = setInterval(() => {
      let nextIndex = currentCardIndex + 1;
      if (nextIndex >= cards.length) {
        nextIndex = 0;
      }
      setCurrentCardIndex(nextIndex);
    }, 8000);

    return () => clearInterval(interval);
  }, [currentCardIndex, cards]);

  if (!cards || !cards.length || dismissed) return null;

  const isLink = !!cards[currentCardIndex]?.href && cards[currentCardIndex]?.href !== '#';
  const isExternal = isLink && cards[currentCardIndex]?.href?.startsWith('http');
  const El = isLink ? Link : 'div';

  return (
    <AnimatePresence mode='popLayout' initial={false}>
      <motion.div
        key={cards[currentCardIndex].id}
        className='relative z-40 flex justify-center overflow-hidden'
        style={{ backgroundColor: cards[currentCardIndex]?.color }}
        initial={{ y: 35 }}
        animate={{ y: 0 }}
        exit={{ y: -35 }}
        transition={{ duration: 0.3 }}
      >
        <El
          data-testid={isLink ? `top_notice_${cards[currentCardIndex].id}` : ''}
          className={twMerge(
            'flex w-full justify-center px-4 py-1.5',
            isLink && 'hover:opacity-70'
          )}
          to={cards[currentCardIndex].href ? cards[currentCardIndex].href! : '#'}
          target={isExternal ? '_blank' : undefined}
          rel='noreferrer'
        >
          <div className='flex w-full max-w-5xl items-center justify-center gap-2'>
            {cards[currentCardIndex]?.icon && (
              <div className='max-h-8 max-w-8 shrink-0'>
                <ContentfulMedia media={cards[currentCardIndex].icon} />
              </div>
            )}
            <p className='text-wrap text-center text-white ltr:pr-7 ltr:lg:pr-0 rtl:pl-7 rtl:lg:pl-0'>
              {cards[currentCardIndex]?.title}
            </p>
          </div>
        </El>

        <button
          data-testid='top_notice_close'
          onClick={() => setDismissed(true)}
          type='button'
          className='absolute top-1/2 flex h-7 w-7 -translate-y-1/2 items-center justify-center rounded-full hover:opacity-70 ltr:right-2 rtl:left-2'
        >
          <img src='/icons/common/close.svg' alt='close' className='h-4 w-4' />
        </button>
      </motion.div>
    </AnimatePresence>
  );
};

export default TopNotice;
