const Vision = () => {
  return (
    <section className='bg-white py-20'>
      <div className='container'>
        <img
          width={1400}
          height={800}
          alt='vision 2030'
          src='/images/about/final_mass_participation_wbk.png'
        />
      </div>
    </section>
  );
};

export default Vision;
