import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetSubscriptionById } from '@wbk/api/hooks';
import { SubscriptionSuccess } from '@wbk/subscription';
import { useAnalytics } from '@wbk/analytics';
import FullpageAnimatedLogo from '@/components/ui/spinners/FullpageAnimatedLogo';
import SubscribeFailed from '@/components/subscription/SubscribeFailed';

const SubscribeSuccess = () => {
  const { id, lang } = useParams<{ id: string; lang: Language }>();

  const { purchaseSubscriptionEvent } = useAnalytics();
  const { data, isLoading, error } = useGetSubscriptionById({
    subscriptionId: id || '',
    lang,
  });

  useEffect(() => {
    if (data) {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({ eventName: 'SUBSCRIBE_SUCCESS', subscription_id: id })
      );
      purchaseSubscriptionEvent(data);
    }
    // eslint-disable-next-line
  }, [data, id]);

  if (isLoading) {
    return <FullpageAnimatedLogo />;
  }

  if (error || !data) {
    return <SubscribeFailed />;
  }

  return (
    <section className='py-12'>
      <SubscriptionSuccess subscription={data} />
    </section>
  );
};

export default SubscribeSuccess;
