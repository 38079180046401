import { RouteObject, redirect } from 'react-router-dom';
import ZoneDetails from '@/pages/zone/slug';

export const zonesRoutes: RouteObject[] = [
  {
    path: 'zone/:slug',
    element: <ZoneDetails />,
  },
  {
    path: 'zones/:slug',
    element: <ZoneDetails />,
  },
  {
    path: 'zone/the-groves/book',
    loader: () => redirect('https://www.thegroves-sa.com'),
  },
  {
    path: 'zones/the-groves/book',
    loader: () => redirect('https://www.thegroves-sa.com'),
  },
];
