const Hero = () => {
  return (
    <section className='container flex items-center justify-center py-10'>
      <img
        src='/images/about/hero.png'
        height={750}
        width={505}
        alt='Fun'
        className='max-h-[308px] w-full max-w-[440px] md:max-h-[505px] md:max-w-[750px]'
      />
    </section>
  );
};

export default Hero;
