import { useTranslation } from 'react-i18next';

const Features = () => {
  const { t } = useTranslation(['about']);
  const features = t('about:features', { returnObjects: true }) as { title: string; url: string }[];

  return (
    <section className='container space-y-16 py-20'>
      <h2 className='text-center text-2xl font-semibold md:text-4xl'>
        {t('about:innovating_global_entertainment')}
      </h2>

      <ul className='mx-auto flex max-w-5xl flex-col gap-6 p-4 md:flex-row'>
        <li className='border-primary-700 px-4 text-xl ltr:border-l-[4px] rtl:border-r-[4px]'>
          {t('about:passion')}
        </li>

        <li className='border-primary-700 px-4 text-xl ltr:border-l-[4px] rtl:border-r-[4px]'>
          {t('about:cutting_edge')}
        </li>
      </ul>

      <ul className='mx-auto flex max-w-3xl flex-wrap justify-center gap-10'>
        {features.map((item, index) => (
          <li key={index} className='flex flex-col items-center gap-4'>
            <img src={item.url} alt={item.title} width={68} height={59} className='invert' />
            <p className='uppercase'>{item.title}</p>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Features;
