import { useTranslation } from 'react-i18next';
import { Button } from '@wbk/ui';
import { AdSlotMLB } from '@smc/ads';
import useExploreFetch from '@/hooks/useExploreFetch';
import useLocalization from '@/context/localization/useLocalization';
import useFilters from '@/context/filters/useSearch';
import EventItemSkeleton from '../ui/spinners/EventItemSkeleton';
import ExploreSortFilter from './filters/Sort';
import ExploreEmptyScreen from './Empty';
import ExploreGrid from './ExploreGrid';
import ExploreEmptySuggestion from './Suggestion';

type Props = Parameters<typeof useExploreFetch>[0];

const ExploreListing = (props: Props) => {
  const { countryCode, fallbackCountry } = useLocalization();
  const { country } = useFilters();
  const { t } = useTranslation('common');
  const {
    allEvents,
    total,
    loading,
    fetchMoreEvents,
    fetchMoreExperiences,
    fetchMorePackages,
    fetchMoreRestaurants,
    fetchMoreShows,
    fetchMoreZones,
    hasMoreExperiences,
    hasMoreEvents,
    hasMorePackages,
    hasMoreRestaurants,
    hasMoreShows,
    hasMoreZones,
  } = useExploreFetch(props);

  const hasMore =
    hasMoreEvents || hasMoreExperiences || hasMoreShows || hasMoreRestaurants || hasMorePackages;

  const selectedCountry = country || countryCode;
  const fetchFallback = !hasMore && selectedCountry !== fallbackCountry;
  const {
    allEvents: fallbackEvents,
    loading: fallbackLoading,
    fetchMoreEvents: fetchFallbackEvents,
    fetchMoreExperiences: fetchFallbackExperiences,
    fetchMorePackages: fetchFallbackPackages,
    fetchMoreRestaurants: fetchFallbackRestaurants,
    fetchMoreShows: fetchFallbackShows,
    hasMoreExperiences: hasFallbackExperiences,
    hasMoreEvents: hasFallbackEvents,
    hasMorePackages: hasFallbackPackages,
    hasMoreRestaurants: hasFallbackRestaurants,
    hasMoreShows: hasFallbackShows,
  } = useExploreFetch({
    predefined: { ...props.predefined, countryCode: fallbackCountry },
    enabled: fetchFallback,
  });

  const hasMoreFallback =
    hasFallbackEvents ||
    hasFallbackExperiences ||
    hasFallbackShows ||
    hasFallbackRestaurants ||
    hasFallbackPackages;

  const enableMore = hasMore || hasMoreFallback;
  const isLoading = loading || fallbackLoading;

  return (
    <>
      {/* Mobile LB */}
      <AdSlotMLB adUnit='explore/mlb' className='mb-4 pt-0' />

      <div className='mb-6 flex items-center justify-between'>
        {total > 0 ? <ExploreSortFilter totalEvents={total} /> : <div />}
      </div>
      <div className='xs:grid-cols-2 grid w-full grow grid-cols-1 gap-4 pt-1 md:grid-cols-3 xl:grid-cols-4'>
        {isLoading && allEvents.length <= 0 ? (
          <EventItemSkeleton number={6} />
        ) : allEvents.length > 0 ? (
          <ExploreGrid events={allEvents} />
        ) : (
          <div className='col-span-full'>
            <ExploreEmptyScreen />
          </div>
        )}
      </div>

      {fallbackEvents.length > 0 && (
        <div className='space-y-4 py-4'>
          <hr className='border-paper' />
          <h4>{t('common:empty_search_suggestion')}</h4>
          <div className='xs:grid-cols-2 grid w-full grow grid-cols-1 gap-4 pt-1 md:grid-cols-3 xl:grid-cols-4'>
            <ExploreGrid events={fallbackEvents} />
          </div>
        </div>
      )}

      {!allEvents.length && !fallbackEvents.length && (
        <div className='space-y-4 py-4'>
          <hr className='border-paper' />
          <ExploreEmptySuggestion />
        </div>
      )}

      {allEvents.length > 0 && (
        <div className='py-12 text-center'>
          <Button
            theme='white'
            shape='outlined'
            className='w-full max-w-[200px]'
            loading={isLoading}
            disabled={!enableMore}
            onClick={() => {
              if (hasMoreEvents) {
                fetchMoreEvents();
              }
              if (hasMoreExperiences) {
                fetchMoreExperiences();
              }
              if (hasMoreShows) {
                fetchMoreShows();
              }
              if (hasMoreRestaurants) {
                fetchMoreRestaurants();
              }
              if (hasMorePackages) {
                fetchMorePackages();
              }
              if (hasMoreZones) {
                fetchMoreZones();
              }
              if (hasFallbackEvents) {
                fetchFallbackEvents();
              }
              if (hasFallbackExperiences) {
                fetchFallbackExperiences();
              }
              if (hasFallbackShows) {
                fetchFallbackShows();
              }
              if (hasFallbackRestaurants) {
                fetchFallbackRestaurants();
              }
              if (hasFallbackPackages) {
                fetchFallbackPackages();
              }
            }}
          >
            {enableMore ? t('common:load_more') : t('common:no_load_more')}
          </Button>
        </div>
      )}
    </>
  );
};

export default ExploreListing;
