import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Button, Typography } from '@wbk/ui';
import { User } from '@wbk/api';
import { WithGrecaptcha } from '@wbk/api/grecaptcha';
import { AnimatedCheck } from '@wbk/svg';
import { COUNTRIES } from '@wbk/utils';
import { FormProvider, useForm } from 'react-hook-form';
import ContactInfo from './ContactInfo';
import BookingDetails from './BookingDetails';
import AdditionalInfo from './AdditionalInfo';
import { AdvertiseFormValues } from './types';

const getDefaultValues = (user?: User): Partial<AdvertiseFormValues> => {
  const country = COUNTRIES.find((c) => c.code === user?.cell_country_code);
  return {
    phoneCountry: country || COUNTRIES.find((c) => c.code === 'SA'),
    phone: user?.cell_number?.replace(`+${country?.dial_code}`, '') || '',
  };
};

const AdvertiseForm = ({ user }: { user?: User }) => {
  const methods = useForm<AdvertiseFormValues>({
    defaultValues: {
      ...getDefaultValues(user),
      mode: 'contact_info',
    },
  });

  const mode = methods.watch('mode');

  const { lang } = useParams();
  const { t } = useTranslation(['common', 'profile', 'advertise']);

  const steps = {
    contact_info: <ContactInfo />,
    booking_details: <BookingDetails />,
    additional_info: <AdditionalInfo />,
  };

  const stepsOrder = ['contact_info', 'booking_details', 'additional_info', 'success'];

  return (
    <FormProvider {...methods}>
      <WithGrecaptcha>
        <div className='container py-8'>
          <div className='space-y-4 pb-5'>
            <Typography variant='heading-XL' as='h1'>
              {t('advertise:title')}
            </Typography>
            <div className='flex items-center gap-1'>
              {Object.keys(steps).map((_, index) => (
                <div
                  key={index}
                  className={`h-0.5 w-full ${index <= stepsOrder.indexOf(mode) ? 'bg-white' : 'bg-paper-level-1'}`}
                />
              ))}
            </div>
          </div>

          <div className='w-full rounded-lg'>
            <div className='flex flex-col items-center justify-between gap-4 lg:flex-row lg:items-start'>
              {mode === 'success' ? (
                <div className='text-center'>
                  <AnimatedCheck className='text-success mx-auto h-40 w-40' />
                  <h2 className='pb-1'>{t('advertise:send_success')}</h2>
                  <p className='text-lg'> {t('advertise:send_success_desc')} </p>
                  <Button
                    as={Link}
                    to={`/${lang}`}
                    className='mx-auto mt-8 w-full max-w-sm'
                    replace
                  >
                    <p className='text-lg font-bold'>{t('common:go_home')}</p>
                  </Button>
                </div>
              ) : (
                steps[mode]
              )}
              <div className='border-paper rounded-L max-w-lg space-y-2 border px-6 pt-6'>
                <Typography variant='heading-M' as='h2'>
                  {t('advertise:reach_millions')}
                </Typography>
                <Typography variant='body-M' className='text-text-secondary'>
                  {t('advertise:reach_millions_desc')}
                </Typography>
                <img src='/images/advertise/iphone.png' alt='advertise' className='w-full' />
              </div>
            </div>
          </div>
        </div>
      </WithGrecaptcha>
    </FormProvider>
  );
};

export default AdvertiseForm;
