import { ContentfulEvent, Restaurant } from '@wbk/contentful/api';

export const globalJsonLd = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'webook.com',
  url: 'https://webook.com',
  image: process.env.VITE_PUBLIC_BOOK_DOMAIN + '/images/default-cover.jpg',
  email: 'support@webook.com',
};

export const searchJsonLd = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  url: 'https://webook.com',
  potentialAction: {
    '@type': 'SearchAction',
    target: {
      '@type': 'EntryPoint',
      urlTemplate: 'https://webook.com/search?q={search_term_string}',
    },
    'query-input': 'required name=search_term_string',
  },
};

export const eventJsonLd = (event: ContentfulEvent, url: string) => {
  const result: Record<string, unknown> = {
    '@context': 'https://schema.org',
    '@type': 'Event',
    name: event.seo?.title || event.title,
    description: event.seo?.description || event.title,
    eventStatus: 'https://schema.org/EventScheduled',
    eventAttendanceMode: 'https://schema.org/MixedEventAttendanceMode',
    startDate: event.schedule?.openDateTime || new Date().toISOString(),
    image: [event.image11?.url],
    location: {
      '@type': 'Place',
      name: event.location?.address || event.zone?.title || event.location?.title || 'N/A',
    },
  };

  if (event.startingPrice && event.currencyCode) {
    result.offers = {
      '@type': 'Offer',
      price: event.startingPrice,
      priceCurrency: event.currencyCode,
      url: process.env.VITE_PUBLIC_BOOK_DOMAIN + url,
    };
  }

  return result;
};

export const restaurantJsonLd = (restaurant: Restaurant, url: string) => {
  const result = {
    '@context': 'https://schema.org',
    '@type': 'Restaurant',
    name: restaurant.seo?.title || restaurant.title,
    openingHours: [restaurant.schedule?.openScheduleText],
    image: [restaurant.image11?.url],
    address: {
      '@type': 'PostalAddress',
      addressLocality:
        restaurant.location?.address ||
        restaurant.zone?.title ||
        restaurant.location?.title ||
        'N/A',
    },
    ur: process.env.VITE_PUBLIC_BOOK_DOMAIN + url,
  };

  return result;
};
