import { useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Button, Gallery, ImageWithPlaceholder } from '@wbk/ui';
import type { Restaurant } from '@wbk/contentful/api';

type IProps = {
  data: Restaurant['menuCollection'] | undefined;
};

const MenuCollection = ({ data }: IProps) => {
  const { t } = useTranslation(['event']);
  const [collapse, setCollapse] = useState(false);
  const [menu, setMenu] = useState(0);

  if (!data || !data.items?.length) return null;

  return (
    <div className='border-paper border-t py-6'>
      <Button
        theme='white'
        shape='text'
        data-collapse={collapse}
        className='bg-body-level-1 w-full data-[collapse=true]:rounded-b-none'
        onClick={() => setCollapse((prev) => !prev)}
      >
        <div className='relative flex h-[62px] w-full items-center justify-between'>
          <div className='flex grow items-center gap-4 px-2'>
            <img
              src={data.items[0].url}
              alt={data.items[0].title}
              className='w-[38px] object-contain ltr:-rotate-12 rtl:rotate-12'
            />
            <p className='text-text-secondary text-base font-semibold'>{t('event:view_menu')}</p>
          </div>
          <img
            src='/icons/common/arrow.svg'
            width={20}
            height={20}
            className={`transition-all ${collapse ? '-rotate-90' : 'rotate-90'}`}
            alt='arrow'
          />
        </div>
      </Button>
      <Gallery
        slides={data.items.map((item) => ({ src: item.url, title: item.title }))}
        startIndex={menu}
        render={({ open }) => (
          <motion.div
            initial='collapsed'
            animate={collapse ? 'open' : 'collapsed'}
            exit='collapsed'
            variants={{
              open: { height: 'auto' },
              collapsed: { height: 0 },
            }}
            className='bg-body-level-1 overflow-hidden rounded-b-md px-1'
          >
            <div className='flex flex-wrap items-start gap-1 py-4'>
              {data.items.map((item, index) => (
                <Button
                  key={item.url + index}
                  theme='white'
                  shape='text'
                  className='min-h-32 p-1'
                  onClick={() => {
                    setMenu(index);
                    open();
                  }}
                >
                  <ImageWithPlaceholder
                    src={item.url}
                    alt={item.title}
                    className='max-h-32 w-24 rounded-md object-contain transition-all hover:scale-105'
                  />
                </Button>
              ))}
            </div>
          </motion.div>
        )}
      />
    </div>
  );
};

export default MenuCollection;
