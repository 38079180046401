import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { languages } from '@/i18n/config';
import useLocalization from '@/context/localization/useLocalization';

/**
 * This is the global headers that will be imported in _document page.
 */
const GlobalHead = () => {
  const { pathname, search } = useLocation();
  const { locale } = useLocalization();
  const path = pathname.replace(`/${locale}`, '');

  return (
    <Helmet>
      <link rel='icon' href='/favicon.ico' />
      <link rel='manifest' href='/manifest.json' />
      <link rel='apple-touch-icon' href='/icons/apple-touch-icon.png' />
      <meta name='theme-color' content='#000000' />
      {process.env.VITE_PUBLIC_ENV !== 'production' && <meta name='robots' content='noindex' />}
      <meta httpEquiv='X-UA-Compatible' content='IE=edge,chrome=1' />
      <meta name='google' content='notranslate' />
      {/* Domain & APIs */}
      {/* <base href={process.env.VITE_PUBLIC_BOOK_DOMAIN} /> */}
      <link rel='preconnect' href={process.env.VITE_PUBLIC_TICKETS_API_TOKEN} />

      {/* Locale */}
      <link
        rel='alternate'
        hrefLang='x-default'
        href={`${process.env.VITE_PUBLIC_BOOK_DOMAIN}/${path}${search}`}
      />
      {languages.map((lang) => (
        <link
          key={lang}
          rel='alternate'
          hrefLang={lang}
          href={`${process.env.VITE_PUBLIC_BOOK_DOMAIN}/${lang}${path}${search}`}
        />
      ))}

      {/* Canonical */}
      <link rel='canonical' href={`${process.env.VITE_PUBLIC_BOOK_DOMAIN}/${locale}${path}`} />

      {/* OG */}
      {languages.map((lang) => (
        <meta key={lang} rel='og:locale:alternate' content={lang} />
      ))}
      <meta property='og:title' content='webook.com' />
      <meta
        property='og:url'
        content={`${process.env.VITE_PUBLIC_BOOK_DOMAIN}/${locale}${path}${search}`}
      />
      <meta property='og:site_name' content='webook.com' />
      <meta property='og:locale' content={locale} />
      <meta property='og:image' content='https://webook.com/images/default-cover.jpg' />
      <meta property='og:image:alt' content='webook.com' />
      <meta property='og:type' content='website' />

      {/* Social */}
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:site' content='@RiyadhSeason' />
      <meta name='twitter:title' content='webook.com' />
      <meta name='twitter:image' content='https://webook.com/images/default-cover.jpg' />
      <meta name='twitter:image:alt' content='webook.com' />

      {/* APP */}
      <meta
        name='apple-itunes-app'
        content='app-id=6468667896, app-argument=https://apps.apple.com/sa/app/webook-com-fun-things-to-do/id6468667896'
      />
      <meta property='al:ios:app_store_id' content='6468667896' />
      <meta
        property='al:ios:url'
        content='https://apps.apple.com/sa/app/webook-com-fun-things-to-do/id6468667896'
      />
      <meta property='al:ios:app_name' content='webook.com' />
      <meta property='al:android:package' content='com.webook.android' />
      <meta
        property='al:android:url'
        content='https://play.google.com/store/apps/details?id=com.webook.android'
      />
      <meta property='al:android:app_name' content='webook.com' />
    </Helmet>
  );
};

export default GlobalHead;
