import { useTranslation } from 'react-i18next';

const TEAM_LIST = [
  {
    title: 'team1',
    image: '/images/about/team1.png',
  },
  {
    title: 'team2',
    image: '/images/about/team2.png',
  },
  {
    title: 'team3',
    image: '/images/about/team3.png',
  },
  {
    title: 'team4',
    image: '/images/about/team4.png',
  },
];

const Team = () => {
  const { t } = useTranslation(['about']);
  const founders = t('about:founders', { returnObjects: true }) as {
    name: string;
    role: string;
    image: string;
    links: { href: string; title: string; icon: string }[];
  }[];
  return (
    <section className='container space-y-12 py-14 pb-44'>
      <div className='mx-auto max-w-3xl space-y-8 text-center'>
        <h2 className='text-2xl md:text-4xl'>{t('our_super_team')}</h2>
        <p className='text-lg'>{t('about:meet_the_team')}</p>
      </div>

      <div className='mx-auto grid max-w-3xl grid-cols-1 gap-20 p-3.5 md:grid-cols-2'>
        {founders.map((founder, index) => (
          <div key={index} className='flex flex-col items-center space-y-3'>
            <div className='relative'>
              <img
                height={344}
                width={290}
                alt={founder.name}
                src={founder.image}
                className='relative z-10'
              />
              <img
                height={344}
                width={290}
                alt='gradient'
                src='/images/about/gradient.gif'
                className='absolute inset-0 translate-x-2 translate-y-2'
              />
            </div>

            <h3 className='text-2xl capitalize'>{founder.name}</h3>
            <p className='text-base uppercase'>{founder.role}</p>
            {founder.links && (
              <div className='flex gap-1'>
                {founder.links.map((link, index) => (
                  <a
                    key={index}
                    href={link.href}
                    title={link.title}
                    target='_blank'
                    rel='noreferrer'
                    className='text-blue-500 hover:underline'
                  >
                    <img width={40} height={40} src={link.icon} alt={link.title} />
                  </a>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      <img
        alt='webook photos'
        src='/images/about/hy_photos.png'
        width={1440}
        height={670}
        className='hidden md:block'
      />

      <div className='flex flex-col md:hidden'>
        {TEAM_LIST.map((item) => (
          <img
            height={319}
            width={567}
            alt={item.title}
            src={item.image}
            key={item.title}
            className='mx-auto'
          />
        ))}
      </div>
    </section>
  );
};

export default Team;
