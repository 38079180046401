import { useTranslation } from 'react-i18next';

const PoweredBy = () => {
  const { t } = useTranslation(['about']);
  return (
    <section className='container space-y-10 py-14'>
      <h2 className='text-center text-2xl capitalize md:text-4xl'>
        {t('about:powered_by_webook')}
      </h2>

      <div className='flex flex-wrap justify-center gap-5 px-8 py-4'>
        {POWERED_BY_LIST.map((logo, index) => (
          <img
            width={150}
            height={80}
            key={index}
            src={logo.href}
            alt={logo.title}
            className='max-h-[80px] w-auto'
          />
        ))}
      </div>
    </section>
  );
};

const POWERED_BY_LIST = [
  {
    href: '/images/about/vs_logo.svg',
    title: 'visit saudi',
  },
  {
    href: '/images/about/alawal_logo.png',
    title: 'alawal park',
  },
  {
    href: '/images/about/sfa_logo.png',
    title: 'sfa dome',
  },
  {
    href: '/images/about/jarir_logo.png',
    title: 'jarir bookstore',
  },
  {
    href: '/images/about/sagp_logo.svg',
    title: 'formula 1 saudi grand prix',
  },
  {
    href: '/images/about/saudi_games_logo.png',
    title: 'saudi games',
  },
];

export default PoweredBy;
