import { useTranslation } from 'react-i18next';

const Awards = () => {
  const { t } = useTranslation(['about']);
  return (
    <section className='container py-14'>
      <h2 className='pb-10 text-center text-2xl font-semibold capitalize md:text-4xl'>
        {t('about:our_awards')}
      </h2>
      <div className='flex flex-wrap justify-center gap-5 px-8 py-4'>
        {AWARDS.map((award, index) => (
          <img width={79} height={80} key={index} src={award} alt={`award_${index}`} />
        ))}
      </div>
    </section>
  );
};

const AWARDS = [
  '/images/about/award_1.png',
  '/images/about/award_2.png',
  '/images/about/award_3.png',
  '/images/about/award_4.png',
  '/images/about/award_5.png',
  '/images/about/award_6.png',
  '/images/about/award_7.png',
  '/images/about/award_8.png',
  '/images/about/award_9.png',
  '/images/about/award_10.png',
];

export default Awards;
