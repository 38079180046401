import { Button, Input, Select, Typography } from '@wbk/ui';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAdvertise } from '@wbk/api/hooks';
import { AdvertiseFormValues } from './types';

const options = [
  'manpower_support',
  'marketing_promotions',
  'live_streaming',
  'technical_integrations_with_third_party',
  'other',
];

const AdditionalInfo = () => {
  const { t } = useTranslation(['common', 'advertise']);
  const { control, watch, handleSubmit, setValue } = useFormContext<AdvertiseFormValues>();
  const { mutateAsync, isPending } = useAdvertise();

  const onSubmit = async (values: AdvertiseFormValues) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { phoneCountry, mode: _mode, ...data } = values;
    try {
      await mutateAsync({
        ...data,
        phone: `${phoneCountry.dial_code} ${values.phone}`,
      });
      setValue('mode', 'success');
    } catch (error) {
      //
    }
  };

  const additional_service = watch('additional_service');

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='w-full max-w-lg space-y-6'>
      <Typography variant='heading-L' as='h2'>
        3. {t('advertise:additional_info.title')}
      </Typography>
      <Controller
        control={control}
        name='additional_service'
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Select
            value={value}
            onChange={(option) => onChange(option.value)}
            error={error?.message}
            label={t('advertise:additional_info.additional_services')}
            placeholder={t('advertise:additional_info.additional_services_placeholder')}
            options={options.map((option) => ({
              value: option,
              text: t(`advertise:additional_info.${option}`),
              id: option,
            }))}
          />
        )}
      />
      {additional_service === 'other' && (
        <Controller
          control={control}
          name='other_additional_service'
          rules={{ required: t('common:validation.required') }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              value={value}
              onChange={onChange}
              label={t('advertise:additional_info.other')}
              error={error?.message}
            />
          )}
        />
      )}

      <Controller
        name='estimated_budget'
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            value={value}
            type='number'
            onChange={onChange}
            label={t('advertise:additional_info.estimated_budget')}
            error={error?.message}
            min={0}
          />
        )}
      />
      <div className='flex flex-wrap items-center justify-between gap-2'>
        <Button
          type='button'
          shape='outlined'
          theme='action'
          onClick={() => setValue('mode', 'booking_details')}
        >
          {t('common:back')}
        </Button>
        <Button theme='action' type='submit' loading={isPending}>
          {t('common:submit')}
        </Button>
      </div>
    </form>
  );
};

export default AdditionalInfo;
