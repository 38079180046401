import { useTranslation } from 'react-i18next';
import Awards from '@/components/about/Awards';
import Description from '@/components/about/Description';
import Download from '@/components/about/Download';
import Hero from '@/components/about/Hero';
import PoweredBy from '@/components/about/PoweredBy';
import Team from '@/components/about/Team';
import Vision from '@/components/about/Vision';
import WbkExperiences from '@/components/business/WbkExperiences';
import Achievements from '@/components/about/Achievements';
import Events from '@/components/about/Events';
import Features from '@/components/about/Features';
import Seo from '@/components/headers/seo';

const AboutPage = () => {
  const { t } = useTranslation(['about']);
  return (
    <>
      <Seo title={t('about:seo.about_title')} description={t('about:seo.about_description')} />
      <Hero />
      <Description />
      <Download />
      <Features />
      <Vision />
      <Achievements />
      <Awards />
      <PoweredBy />
      <Events />
      <Team />
      <WbkExperiences title={t('about:trusted_by')} showAll />
    </>
  );
};

export default AboutPage;
