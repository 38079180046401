import { useTranslation } from 'react-i18next';
import { AuthForm } from '@wbk/auth';
import Seo from '@/components/headers/seo';

const LoginPage = () => {
  const { t } = useTranslation('common');
  return (
    <>
      <Seo title={t('common:auth.login')} />
      <AuthForm title={t('common:auth.login_webook')} className='m-0' />
    </>
  );
};

export default LoginPage;
