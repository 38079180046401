import { useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import {
  isSameDayFromMilli,
  isSameMonthFromMilli,
  isSameYearFromMilli,
  splitDateTime,
} from '@wbk/utils';

type Props = {
  startDateTime: number;
  endDateTime: number;
  className?: string;
  showTime?: boolean;
};

const TicketingEventDateTime = ({
  startDateTime,
  endDateTime,
  className = '',
  showTime = true,
}: Props) => {
  const { lang } = useParams<{ lang: Language }>();

  const locale = lang?.split('-')?.[0] || 'en';

  const start = splitDateTime(startDateTime * 1000, `${locale}-GB`);
  const end = splitDateTime(endDateTime * 1000, `${locale}-GB`);

  const isSameYear = isSameYearFromMilli(startDateTime, endDateTime);
  const isSameMonth = isSameMonthFromMilli(startDateTime, endDateTime);
  const isSameDay = isSameDayFromMilli(startDateTime, endDateTime);

  return (
    <div className={twMerge('flex items-center gap-1.5 text-sm uppercase', className)}>
      {/* {showIcon && <img src='/icons/common/calendar.svg' width={20} height={20} alt='calendar' />} */}
      <p data-testid='event-date'>
        {isSameDay
          ? `${start.day} ${start.month} ${start.year} ${showTime ? ` - ${start.time}` : ''}`
          : isSameYear && isSameMonth
            ? `${start.day} - ${end.day} ${start.month} ${start.year} ${showTime ? ` | ${start.time}` : ''}`
            : isSameYear
              ? `${start.day} ${start.month} - ${end.day} ${end.month} ${end.year}`
              : `${start.day} ${start.month} ${start.year} - ${end.day} ${end.month} ${end.year}`}
      </p>
    </div>
  );
};

export default TicketingEventDateTime;
