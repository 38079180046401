import { useTranslation } from 'react-i18next';
import { Carousel } from '@wbk/ui';

const Events = () => {
  const { t } = useTranslation(['about']);
  const events = t('about:events', { returnObjects: true }) as {
    title: string;
    description: string;
    image: string;
  }[];

  return (
    <section className='container space-y-14 py-14'>
      <h2 className='text-center text-2xl font-semibold md:text-4xl'>
        {t('about:successful_events')}
      </h2>

      <Carousel arrows='middle' options={{ dragFree: true }}>
        {events.map((event, index) => (
          <div
            key={index}
            className='relative mr-6 flex shrink-0 basis-full flex-col sm:basis-3/4 md:basis-3/5 lg:basis-1/2 xl:basis-2/5'
          >
            <img
              src={event.image}
              alt={event.title}
              width={526}
              height={526}
              className='w-full object-cover'
            />
            <div className='absolute inset-0 scale-[1.02] bg-gradient-to-b from-transparent via-black/50 to-black/95 to-85%'></div>
            <div className='absolute bottom-0 w-full space-y-2 p-4'>
              <h3 className='text-2xl capitalize md:text-4xl'>{event.title}</h3>
              <p className='uppercase'>{event.description}</p>
            </div>
          </div>
        ))}
      </Carousel>
    </section>
  );
};

export default Events;
