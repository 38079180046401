import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Gallery } from '@wbk/ui';
import { CurrencyPriceLabel } from '@wbk/currency';
import { BuyTicketButton, ContentfulButton, ContentfulRichText } from '@wbk/contentful';
import { CheckIcon } from '@wbk/svg';
import type { Restaurant } from '@wbk/contentful/api';

type Props = {
  data: Restaurant;
};

const RestaurantSummary = ({ data }: Props) => {
  const { t } = useTranslation(['common', 'event']);
  const [menu, setMenu] = useState(0);

  const features = data.features?.qaListCollection?.items;
  const socialAccounts = data.socialLinks?.iconLinksCollection?.items;

  return (
    <div className='bg-body-level-1/30 top-20 z-10 rounded-xl px-4 py-3 backdrop-blur-xl lg:sticky'>
      <div className='space-between flex items-center justify-between gap-4'>
        <div className='min-w-32 shrink-0 leading-none'>
          <span className='text-xs'>{t('event:booking_fee')}</span>
          <CurrencyPriceLabel
            originalPrice={data.startingPrice}
            currency={data.currencyCode}
            className='text-xl font-semibold md:text-2xl'
            changeable
          />
          <span className='text-text-secondary text-xs'>{t('event:per_person')}</span>
        </div>

        <BuyTicketButton event={data} className='font-medium' id='buyButton' />
      </div>
      {data?.menuCollection?.items?.length && (
        <Gallery
          slides={data.menuCollection.items.map((item) => ({ src: item.url, title: item.title }))}
          startIndex={menu}
          render={({ open }) => {
            return (
              <>
                {!!data.menuCollection?.items?.length && (
                  <>
                    <Divider className='my-2 block md:hidden' />

                    <div className='block md:hidden'>
                      <Button
                        shape='outlined'
                        theme='white'
                        className='flex h-11 w-full rounded-lg border-white/30 font-medium capitalize'
                        onClick={() => {
                          setMenu(0);
                          open();
                        }}
                      >
                        <img
                          src='/icons/common/clipboard.svg'
                          alt='view menu'
                          width={20}
                          height={20}
                        />
                        <span>{t('event:menu')}</span>
                      </Button>
                    </div>
                  </>
                )}
              </>
            );
          }}
        />
      )}

      {features && features.length > 0 && (
        <>
          <Divider className='my-2' />

          <ul className='space-y-3 py-2 text-sm'>
            {features.map((item, index) => {
              return (
                <li className='flex items-center gap-2' key={index}>
                  <CheckIcon className='text-success h-6 w-6 shrink-0' />
                  <span>{item.title}</span>
                </li>
              );
            })}
          </ul>
        </>
      )}

      {socialAccounts && socialAccounts.length > 0 && (
        <>
          <Divider className='my-2' />

          <div className='flex items-center justify-between gap-2 md:gap-4'>
            <p className='shrink-0 font-semibold'>{t('event:social_accounts')}</p>

            <ul className='flex flex-wrap items-start'>
              {socialAccounts.map((link) => {
                if (!link) return null;

                return (
                  <li key={link.id}>
                    <ContentfulButton
                      link={link}
                      rel='noreferrer noopener'
                      className='event_social_media_follow h-11 w-11 overflow-hidden rounded-md p-0.5'
                      data-category={link.label}
                      rounded
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      )}

      {data.contact?.description && (
        <>
          <Divider className='my-2' />

          <div className='flex items-center justify-between py-3'>
            <p className='font-semibold'>{data.contact?.title || t('event:contact_info')}</p>

            <div dir='ltr' className='[&_a]:text-blue-400'>
              <ContentfulRichText description={data.contact.description} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RestaurantSummary;
