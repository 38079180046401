import AnimatedGrayLogo from './AnimatedGrayLogo';

const FullpageAnimatedLogo = ({ withHeader = false, withLogo = true }) => {
  return (
    <>
      <style>{'body{overflow:hidden}'}</style>
      {withHeader && (
        <header className='border-body-dark/10 bg-body relative z-20 h-[48px] w-full border-b md:h-[70px]'></header>
      )}
      <div className='relative flex h-[calc(100svh-50px)] items-center justify-center overflow-hidden rounded-md py-20'>
        {withLogo && <AnimatedGrayLogo className='h-24 w-24' />}
      </div>
    </>
  );
};

export default FullpageAnimatedLogo;
