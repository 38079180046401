import { RouteObject } from 'react-router-dom';
import AuthLayout from '@/pages/auth/layout';
import WebviewLogin from '@/pages/webview';
import HeaderWith404 from '@/components/error/Header404';
import LoginPage from '@/pages/auth/login';
import SignupPage from '@/pages/auth/signup';

export const authRoutes: RouteObject[] = [
  {
    path: 'login',
    element: <AuthLayout />,
    children: [
      {
        path: '',
        element: <LoginPage />,
      },
    ],
  },
  {
    path: 'signup',
    element: <AuthLayout />,
    children: [
      {
        path: '',
        element: <SignupPage />,
      },
    ],
  },
  {
    path: 'webview',
    errorElement: <HeaderWith404 />,
    element: <WebviewLogin />,
    loader: ({ request }) => {
      const searchParams = new URL(request.url).searchParams;
      const redirect = searchParams.get('redirect') || '/';
      return { redirect };
    },
  },
  {
    path: ':type/:slug/book/app',
    errorElement: <HeaderWith404 />,
    element: <WebviewLogin />,
  },
];
