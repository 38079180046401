import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@wbk/ui';
import { useShopProducts } from '@wbk/api/hooks';
import useLocalization from '@/context/localization/useLocalization';
import ProductsCarousel from './ProductsCarousel';

const HomeShopSection = () => {
  const { t } = useTranslation(['common']);
  const { locale } = useLocalization();
  const { data } = useShopProducts({
    lang: locale,
    params: { category_slug: 'home' },
    isHomePage: true,
  });

  if (!data || data.length === 0) return null;

  return (
    <section data-testid='home_shop_section' className='py-8'>
      <img
        src='/images/illustration/section-triangle.svg'
        width={1680}
        height={51}
        alt=''
        className='-mb-px block w-full'
      />

      <div className='bg-lime-900'>
        <div className='container py-4'>
          <div className='mb-2 flex gap-1.5 align-middle font-extrabold text-lime-500'>
            <img
              src={`/icons/the_shop/WBKShop-${locale}.png`}
              width={132}
              height={27}
              alt='webook shop logo'
            />
          </div>
          <Typography color='text-secondary' className='mb-1'>
            {t('common:shop.featured_products')}
          </Typography>
          <ProductsCarousel products={data} showBuyButton={false} />
          <div className='flex items-center justify-center py-4'>
            <Button
              as='a'
              target='_blank'
              data-testid='home_go_to_shop_button'
              href={`https://webook.com/shop${locale?.startsWith('en') ? '/en/' : ''}`}
              className='h-11 w-full max-w-[360px] bg-lime-500 text-[17px] font-semibold text-black hover:bg-lime-400 focus:ring-lime-600 active:bg-lime-600'
              arrow
            >
              {t('common:shop.go_to_webook_shop')}
            </Button>
          </div>
        </div>
      </div>

      <img
        src='/images/illustration/section-triangle.svg'
        width={1680}
        height={51}
        alt=''
        className='-mt-px block w-full -scale-100'
      />
    </section>
  );
};

export default HomeShopSection;
