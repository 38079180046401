import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAnalytics } from '@wbk/analytics';
import { useGetEventGroupBySlug } from '@wbk/api/hooks';
import { ImageWithPlaceholder } from '@wbk/ui';
import Error404 from '@/components/error/404';
import EventTitleDetails from '@/components/group/TitleDetails';
import FullpageAnimatedLogo from '@/components/ui/spinners/FullpageAnimatedLogo';
import EventTitleDescription from '@/components/group/Description';
import GalleryWithThumbs from '@/components/events/gallery/GalleryWithThumbs';

const EventGroupPage = () => {
  const { slug, lang } = useParams<{ lang: Language; slug: string }>();
  const { data, isLoading } = useGetEventGroupBySlug({ slug: slug, lang });
  const { viewItemEvent } = useAnalytics();

  useEffect(() => {
    // GA4 Event
    if (data) {
      const tickets = data.events.upcoming;
      const firstPrice = tickets[0]?.price || 0;
      const minPrice = tickets.reduce((min, event) => Math.min(min, event.price), firstPrice);

      viewItemEvent({
        event: {
          _id: data._id,
          slug: slug || '',
          title: data.title,
          startingPrice: minPrice,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isLoading) {
    return <FullpageAnimatedLogo />;
  }

  if (!data) {
    return <Error404 />;
  }

  return (
    <>
      <div className='container flex flex-col items-start gap-6 space-y-6 py-4 pb-20 xl:flex-row xl:space-y-0'>
        <section className='w-full grow'>
          <EventTitleDetails event={data} />
          <EventTitleDescription event={data} />
        </section>

        <aside className='sticky top-24 mx-auto w-full max-w-xl shrink-0 grow gap-8 xl:max-w-lg'>
          {data.gallery_images?.length > 0 ? (
            <GalleryWithThumbs
              poster={{ url: data.poster }}
              carousel={data.gallery_images?.map((url) => ({ url }))}
            />
          ) : (
            <figure className='rounded-lg'>
              <ImageWithPlaceholder
                className='aspect-1 overflow-hidden rounded-lg object-cover'
                src={data.poster}
                width={600}
                height={600}
                alt={data.title}
              />
            </figure>
          )}
        </aside>
      </div>
    </>
  );
};

export default EventGroupPage;
