import { useTranslation } from 'react-i18next';

const Description = () => {
  const { t } = useTranslation(['about']);

  return (
    <section className='container max-w-[830px] space-y-10 py-20 text-center'>
      <h1 className='text-2xl font-semibold md:text-3xl'>{t('about:your_super_app_for_fun')}</h1>
      <p className='text-lg'>{t('about:discover_things_around')}</p>
      <ul className='flex flex-wrap items-center justify-center gap-6'>
        {PARTNERS.map((item) => (
          <li key={item.title}>
            <img width={91} height={50} src={item.image} alt={item.title} className='h-14 w-auto' />
          </li>
        ))}
      </ul>
    </section>
  );
};

const PARTNERS = [
  {
    title: 'Vision 2030',
    image: '/images/about/vision_2030.png',
  },
  {
    title: 'General Entertainment Authority',
    image: '/images/about/gea.svg',
  },
  {
    title: 'Ministry of Sports',
    image: '/images/about/mos_logo.png',
  },
  {
    title: 'Ministry of Culture',
    image: '/images/about/moc_logo.png',
  },
  {
    title: 'Saudi Motorsport',
    image: '/images/about/smc_logo.png',
  },
  {
    title: 'General Sports Authority',
    image: '/images/about/gsa_logo.png',
  },
  {
    title: 'Visit Saudi',
    image: '/images/about/vs_logo.svg',
  },
];

export default Description;
