import { ContentfulMedia, MobileStickyBuy } from '@wbk/contentful';
import { ShareModal } from '@wbk/ui';
import type { Restaurant } from '@wbk/contentful/api';

type Props = {
  event: Restaurant;
};

const RestaurantDetails = ({ event }: Props) => {
  const tags = event.tags?.length && (
    <div className='text-text-secondary flex flex-wrap items-center text-xs'>
      {event.tags.map((tag, i) => (
        <div key={i} className='flex'>
          <span className='shrink-0'>{tag}</span>
          {i !== event.tags.length - 1 && <span className='text-text-secondary mx-1'>•</span>}
        </div>
      ))}
    </div>
  );

  return (
    <>
      <div className='mb-2 md:hidden'>{tags}</div>
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-4'>
          <ContentfulMedia
            className='hidden rounded-md object-contain object-center md:block'
            ratio='1x1'
            media={event?.logo}
            width={100}
            height={100}
            placeholder
          />

          <ContentfulMedia
            className='rounded-md object-contain object-center md:hidden'
            ratio='1x1'
            media={event?.logo}
            width={58}
            height={58}
            placeholder
          />

          <div className='line-clamp-2'>
            <div className='mb-2 hidden md:block'>{tags}</div>
            <h1 className='mb-2 text-2xl font-semibold md:text-4xl'>{event.title}</h1>
            {event.location?.address && <p className='text-sm'>{event.location.address}</p>}
          </div>
        </div>
        <div className='hidden md:block'>
          <ShareModal
            data={{
              title: event?.title,
              text: event?.title,
            }}
            poster={event.image11.url}
          />
        </div>
      </div>

      {/* Mobile sticky */}
      <MobileStickyBuy event={event} />
    </>
  );
};

export default RestaurantDetails;
