import { AboutSection } from '@wbk/contentful';
import ContentfulWebSection from './WebSection';
import type { ContentfulPage } from '@wbk/contentful/api';

type Props = {
  section: ContentfulPage['sectionsCollection']['items'][0];
};

const PageSection = ({ section }: Props) => {
  switch (section.__typename) {
    case 'WebSection':
      return <ContentfulWebSection section={section} />;
    // case 'AppReferralCard':
    //   return <ReferralBanner section={section} />;
    case 'AboutSection':
      return <AboutSection section={section} />;
    default:
      return null;
  }
};

export default PageSection;
