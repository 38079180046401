import { supportedLngs } from '@/i18n/config';
import { ContentfulConfig } from '@wbk/contentful';

ContentfulConfig.init({
  base: process.env.VITE_CDN_BASE_URL!,
  accessToken: process.env.VITE_CONTENTFUL_ACCESS_TOKEN!,
  spaceId: process.env.VITE_CONTENTFUL_SPACE_ID!,
  environment: process.env.VITE_CONTENTFUL_ENVIRONMENT!,
  supportLocales: supportedLngs,
});
