import { Fragment } from 'react';
import {
  EventItemSkeleton as ContentfulEventItemSkeleton,
  EventListItemSkeleton,
} from '@wbk/contentful';

const EventItemSkeleton = ({ number = 6 }) => {
  const array = Array.from(Array(number).keys());

  return array.map((i) => (
    <Fragment key={i}>
      <EventListItemSkeleton className='lg:hidden' />
      <ContentfulEventItemSkeleton className='hidden lg:block' />
    </Fragment>
  ));
};

export default EventItemSkeleton;
