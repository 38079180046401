import { useMemo } from 'react';
import { useGetZones } from '@wbk/contentful/api';
import { Button } from '@wbk/ui';
import { CloseIcon } from '@wbk/svg';
import useFilters from '@/context/filters/useSearch';
import useLocalization from '@/context/localization/useLocalization';

const SelectedZones = () => {
  const { locale, countryCode } = useLocalization();
  const { zone, selectFilter } = useFilters();

  const { data } = useGetZones({
    lang: locale,
    where: { location: { countryCode } },
  });

  const selectedZones = useMemo(() => {
    return data?.items?.filter((z) => zone?.includes(z.slug));
  }, [data, zone]);

  if (!selectedZones?.length) return null;

  return selectedZones.map((zone) => (
    <span
      key={zone.id}
      className='flex items-center gap-1 rounded-full border px-2 py-0.5 text-sm capitalize'
    >
      {zone.title}
      <Button
        theme='white'
        shape='text'
        className='p-1.5'
        onClick={() => {
          selectFilter({ key: 'zone', value: [zone.slug] });
        }}
      >
        <CloseIcon className='h-3 w-3' />
      </Button>
    </span>
  ));
};

export default SelectedZones;
