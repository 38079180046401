import { Fragment } from 'react';
import { usePage } from '@wbk/contentful/api';
import { AdSlotLB, AdSlotMLB, AdSlotMPU, AdSlotVideo } from '@smc/ads';
import { useDeviceDetect } from '@wbk/hooks';
import FullpageAnimatedLogo from '@/components/ui/spinners/FullpageAnimatedLogo';
import PageSection from '@/components/section';
import useLocalization from '@/context/localization/useLocalization';

const Home = () => {
  const { locale, countryCode, detectLoading } = useLocalization();
  const { isMobile, isDesktop } = useDeviceDetect();
  const {
    data: home,
    isLoading,
    error,
  } = usePage(
    {
      lang: locale,
      limit: 1,
      where: {
        slug_contains: 'webook-home',
        ...(countryCode ? { country: { countryCode } } : { country_exists: false }),
      },
    },
    { enabled: !detectLoading }
  );

  if (isLoading || detectLoading) {
    return <FullpageAnimatedLogo />;
  }

  if (error) {
    throw error;
  }

  if (home) {
    return home?.sectionsCollection.items.map((section, idx) => (
      <Fragment key={section.__typename + idx}>
        {idx === 3 && (
          <>
            <AdSlotLB adUnit='home/lb' />
            <AdSlotMLB adUnit='home/mlb' />
          </>
        )}
        {isMobile && (
          <>
            {idx === 4 && <AdSlotVideo />}
            {idx === 5 && (
              <AdSlotMPU slotId={`${section.id}-${idx}`} adUnit='home/mmpu' className='mx-auto' />
            )}
          </>
        )}
        {isDesktop && idx === 5 && <AdSlotVideo />}
        <PageSection section={section} />
      </Fragment>
    ));
  }

  return null;
};

export default Home;
