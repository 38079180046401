import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Context, { FilterKeys, FiltersContextProps, FiltersStateProps } from './context';

type Props = {
  children: React.ReactNode;
};

const FiltersProvider = ({ children }: Props) => {
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [state, setState] = useState<FiltersStateProps>({});

  const setFromParams = useCallback(() => {
    setState({
      date: searchParams.get('date'),
      price: searchParams.get('price'),
      country: searchParams.get('country'),
      city: searchParams.get('city'),
      zone: searchParams.getAll('zone'),
      category: searchParams.getAll('category'),
      tag: searchParams.getAll('tag'),
      sort: searchParams.get('sort'),
      q: searchParams.get('q'),
    });
  }, [searchParams]);

  useEffect(() => {
    // set initial state from URL
    // This is needed to solve the problem of saerching within the explore page
    setFromParams();
  }, [setFromParams]);

  const resetFilters = () => {
    setFromParams();
  };

  const clearFilters = () => {
    setState({});

    const hasFilters = searchParams.size > 0;
    if (hasFilters) {
      navigate(pathname, { replace: true });
    }
  };

  const selectFilter: FiltersContextProps['selectFilter'] = ({ key, value, apply = true }) => {
    const newUrl = new URLSearchParams(searchParams);
    newUrl.delete('page');

    if (Array.isArray(value)) {
      const val = value[0];
      setState((prevState) => {
        const prevVal = (prevState[key] as string[]) || [];
        return {
          ...prevState,
          [key]: prevVal.includes(val) ? prevVal.filter((v) => v !== val) : prevVal.concat(val),
        };
      });

      const prevValues = newUrl.getAll(key);
      const exists = prevValues.includes(val);
      const newValues = exists ? prevValues.filter((v) => v !== val) : prevValues.concat(val);
      newUrl.delete(key);
      newValues.forEach((v) => newUrl.append(key, v));
    } else {
      const sameValue = state[key] === value;
      setState((prevState) => ({ ...prevState, [key]: sameValue ? null : value }));

      if (!value || sameValue) {
        newUrl.delete(key);
      } else {
        newUrl.set(key, value);
      }
    }

    // replace immediately
    if (apply) {
      const search = newUrl.size > 0 ? `?${newUrl.toString()}` : '';
      navigate(`${pathname}${search}`, { replace: true });
    }
  };

  const applyFilters = () => {
    const newUrl = new URLSearchParams(searchParams);
    newUrl.delete('page');

    for (const key in state) {
      const value = state[key as FilterKeys];
      if (Array.isArray(value)) {
        newUrl.delete(key);
        if (value.length > 0) {
          value.forEach((v) => newUrl.append(key, v));
        }
      } else {
        if (value) {
          newUrl.set(key, value);
        } else {
          newUrl.delete(key);
        }
      }
    }

    const search = newUrl.size > 0 ? `?${newUrl.toString()}` : '';
    navigate(`${pathname}${search}`, { replace: true });
  };

  const selectAndApply: FiltersContextProps['selectAndApply'] = (filters) => {
    const newUrl = new URLSearchParams();
    // date, sort, and q does not need to be reset
    const valuesToKeep = ['date', 'sort', 'q'];
    for (const key of valuesToKeep) {
      const value = state[key as FilterKeys] as string;
      if (value) {
        newUrl.set(key, value);
      }
    }

    for (const filter of filters) {
      const key = filter.key;
      const value = filter.value;
      if (Array.isArray(value)) {
        newUrl.delete(key);
        if (value.length > 0) {
          value.forEach((v) => newUrl.append(key, v));
        }
      } else {
        if (value) {
          newUrl.set(key, value);
        } else {
          newUrl.delete(key);
        }
      }
    }

    // Reset state from the values of newUrl
    const newState: { [key: string]: string } = {};
    for (const param of newUrl) {
      const key = param[0];
      const value = param[1];
      newState[key] = value;
    }
    setState(newState);

    const search = newUrl.size > 0 ? `?${newUrl.toString()}` : '';
    navigate(`${pathname}${search}`, { replace: true });
  };

  const total = useMemo(() => {
    let amount = 0;
    for (const key in state) {
      const excludes = ['sort', 'country', 'city', 'q'];
      if (excludes.includes(key)) {
        continue;
      }

      const value = state[key as FilterKeys];
      if (Array.isArray(value)) {
        amount += value.length;
      } else {
        amount += value ? 1 : 0;
      }
    }

    return amount;
  }, [state]);

  return (
    <Context.Provider
      value={{
        ...state,
        total,
        selectFilter,
        applyFilters,
        selectAndApply,
        resetFilters,
        clearFilters,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default FiltersProvider;
