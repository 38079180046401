import { DatadogLogger } from '@wbk/logger/react';
import packageJson from './package.json';

export const logger = new DatadogLogger({
  clientToken: import.meta.env.VITE_PUBLIC_DATADOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  env: import.meta.env.VITE_PUBLIC_ENV,
  // Specify a version number to identify the deployed version of your application in Datadog
  version: packageJson.version,
  sessionSampleRate: import.meta.env.VITE_PUBLIC_ENV === 'production' ? 20 : 100,
  forwardErrorsToLogs: false,
  // forwardConsoleLogs: ['error'],
  service: 'webook-browser',
});
