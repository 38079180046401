import { useTranslation } from 'react-i18next';
import { Package } from '@wbk/contentful/api';
import { CollapsableText, MarkdownRender, Sponsors } from '@wbk/contentful';
import { CheckIcon } from '@wbk/svg';

type Props = {
  event: Package;
};

const PackageDescription = ({ event }: Props) => {
  const { t } = useTranslation('event');

  return (
    <>
      {event.description && (
        <div className='space-y-2 border-white/20' data-testid='event-description'>
          <h2 className='text-xl'>{t('event:about')}</h2>
          <CollapsableText
            text={event.description}
            btnProps={{ 'aria-label': `Read More About ${event.title}` }}
            markdown={typeof event.description === 'string'}
          />
        </div>
      )}

      {event.specialPromotion && (
        <div className='space-y-4 border-b border-white/20 pb-6'>
          <MarkdownRender text={event.specialPromotion} />
        </div>
      )}

      {event.contentListCollection?.items?.length && (
        <div className='space-y-8 border-b border-white/20 pb-6'>
          {event.contentListCollection?.items?.map((qaList) => (
            <div key={qaList.id} className='space-y-2'>
              {qaList.title && <h2 className='text-xl'>{qaList.title}</h2>}
              <ul className='space-y-1'>
                {qaList.qaListCollection?.items?.map((qa, i) => (
                  <li key={i} className='flex items-start gap-2'>
                    <CheckIcon className='text-success mt-0.5 h-5 w-5 shrink-0' />
                    <p>{qa.title}</p>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}

      {event.richTerms && (
        <div className='space-y-4 border-b border-white/20 pt-6'>
          <h2 className='text-xl'>{t('common:nav.terms_and_conditions')}</h2>
          <CollapsableText
            text={event.richTerms}
            btnProps={{ 'aria-label': 'Read More About Terms' }}
          />
        </div>
      )}

      {!!event?.sponsorsCollection?.items?.length && (
        <div className='space-y-4 border-t border-white/20 py-6'>
          <Sponsors sponsors={event.sponsorsCollection} />
        </div>
      )}
    </>
  );
};

export default PackageDescription;
