import type { ContentfulEvent } from '@wbk/contentful/api';

export const parseContentfulToAnalyticsEvent = (contentfulEvent?: ContentfulEvent) => {
  return {
    _id: contentfulEvent?.id || '',
    slug: contentfulEvent?.slug || '',
    poster: contentfulEvent?.image11?.url || '',
    title: contentfulEvent?.title || '',
    startingPrice: contentfulEvent?.startingPrice,
    currencyCode: contentfulEvent?.currencyCode,
    category: contentfulEvent?.category?.id || 'N/A',
    zone: contentfulEvent?.zone?.slug || 'N/A',
    start_date_time: contentfulEvent?.schedule?.openDateTime
      ? new Date(contentfulEvent.schedule.openDateTime).getTime() / 1000
      : undefined,
    end_date_time: contentfulEvent?.schedule?.closeDateTime
      ? new Date(contentfulEvent.schedule.closeDateTime).getTime() / 1000
      : undefined,
  };
};
